import { colors } from '@styles';
import styled from 'styled-components';

export const Breadcrumbs = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: ${colors.green};
    text-decoration: none;
  }
`;

export const Container = styled.div`
  padding: 16px 0;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const SectionContainer = styled.div`
  padding: 16px;
  background-color: ${colors.white};
  border: 1px solid #dfdfe8;
  border-radius: 8px;
`;

export const CredsAlert = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #dbe5ff;
  border-radius: 6px;
`;

export const CredsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CredsTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const CredsSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
`;
