import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import projectApi from '@services/api/projectApi';
import { Invoice } from '@generated/types/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector, useToast } from '..';

type UpdateInvoicePayload = {
  title?: string;
  description?: string;
  dueDate?: Date;
  amount?: number;
  amountPaid?: number;
  relatedToRecordId?: number;
  status?: string;
};

export const useUpdateInvoice = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<Invoice, Error, { id: number; dto: UpdateInvoicePayload }>(
    async ({ id, dto }) => {
      try {
        const { data: invoice } = await projectApi.updateInvoice(
          id,
          {
            title: dto.title,
            description: dto.description,
            dueDate: dto.dueDate,
            amount: dto.amount,
            projectId: dto.relatedToRecordId,
            status: dto.status,
            amountPaid: dto.amountPaid || 0
          },
          companyId
        );

        return invoice;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (invoice) => {
        showSuccess(`${invoice.isBill ? 'Bill' : 'Invoice'} updated successfully`);
        queryClient.invalidateQueries([ReactQueryKey.InvoiceList]);
      }
    }
  );
};
