import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { useCreateAuroraIntegration } from '@hooks/workspace/connectIntegration';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useCallback } from 'react';

interface FormValues {
  tenantId: string;
  token: string;
}

interface Props {
  onClose: (newId: number | void) => void;
}

const RULES: FormValidationRules<FormValues> = {
  tenantId: {
    isRequired: true
  },
  token: {
    isRequired: true
  }
};

export const CreateAuroraIntegration = ({ onClose }: Props) => {
  const { mutateAsync: create } = useCreateAuroraIntegration();

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: async ({ tenantId, token }) => {
      const result = await create({ token, tenant: tenantId });

      onClose(result.id);
    }
  });

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    control,
    formState: { isSubmitting }
  } = form;

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <ModalBody>
        <InputField control={control} name="tenantId" label="Tenant ID" />
        <InputField control={control} name="token" label="Bearer token" />
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancel} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Create
        </Button>
      </ModalFooter>
    </Form>
  );
};
