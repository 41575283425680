import React from 'react';
import { Select } from '@kit/ui/Select';
import { ConnectIntegration } from '@generated/types/graphql';
import { useWorkflowList } from '@hooks/workflows/useWorkflowList';
import { useUpdateConnectIntegration } from '@hooks/workspace/connectIntegration';
import { SubDescription, SubTitle } from '../styled';

type Props = {
  integration: ConnectIntegration;
};

export const WorkflowSelect = ({ integration }: Props) => {
  const { data: workflows = [] } = useWorkflowList();

  const { mutateAsync: updateIntegration } = useUpdateConnectIntegration();

  const handleUpdateWorkflow = async (workflowId?: number) => {
    await updateIntegration({ integrationId: integration.id, dto: { blueprintId: workflowId || null } });
  };

  return (
    <>
      <SubTitle>Workflow</SubTitle>
      <SubDescription>Select workflow which will be assigned to every new project.</SubDescription>
      <Select
        placeholder="Select workflow"
        options={workflows}
        value={integration.blueprint}
        getOptionLabel={(option) => option.name}
        onChange={(_, value) => handleUpdateWorkflow(value?.id)}
      />
    </>
  );
};
