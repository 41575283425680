import { InfiniteData, useInfiniteQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { System, SystemCondition, SystemFilter, SystemsConnection } from '@generated/types/graphql';
import { DeepPartial } from 'redux';
import { SortField, sortToExpr } from '@hooks/systems/constants';
import { UseQueryOptions } from 'react-query/types/react/types';
import { SystemWithStats } from '@hooks/systems/types';
import { apiErrorHandler } from '@utils';
import { useMemo } from 'react';
import { mergeSystemMetricsStatsData, useSystemsMetricsStatsPaginated } from './useSystemsMetricsStatsPaginated';

export const useSystems = (
  args: {
    condition?: DeepPartial<SystemCondition>;
    filter?: DeepPartial<SystemFilter>;
    first?: number;
    orderBy?: [field: SortField, desc?: boolean][];
  },
  opts: UseQueryOptions<System[]> = {},
  initialData?: SystemWithStats[]
) => {
  const { data, isFetching, fetchNextPage, hasNextPage } = useInfiniteQuery<System[]>(
    [ReactQueryKey.System, 'useSystems', args],
    async ({ pageParam = 0 }) => {
      const { condition, filter, first = 10, orderBy } = args;

      try {
        const variables = {
          condition,
          filter,
          first,
          offset: pageParam,
          orderBy: sortToExpr(orderBy)
        };

        const response = await postGraphql<{ systemsConnection: SystemsConnection }>(
          gql`
            query SYSTEM_LIST_QUERY(
              $condition: SystemCondition
              $filter: SystemFilter
              $first: Int
              $offset: Int
              $orderBy: [SystemsOrderBy!]
            ) {
              systemsConnection(
                condition: $condition
                filter: $filter
                first: $first
                offset: $offset
                orderBy: $orderBy
              ) {
                nodes {
                  id
                  createdAt
                  monitored
                  providerId
                  status
                  operationalAt
                  address
                  addressForced
                  addressCity
                  addressState
                  addressStreet
                  addressZip
                  lastReportAt
                  connectionType
                  size
                  providerStatus
                  name
                  number
                  clientType
                  uuid
                  notes
                  installedAt
                  operationStatus

                  integration {
                    id
                    provider
                  }

                  project {
                    id
                  }

                  profile {
                    id
                    name
                  }

                  systemAlertsBySystemIdConnection(
                    first: 1
                    filter: { status: { equalTo: ACTIVE } }
                    orderBy: CREATED_AT_DESC
                  ) {
                    nodes {
                      id
                      alertConfig {
                        name
                      }
                    }
                  }
                }
              }
            }
          `,
          variables
        );

        return response.systemsConnection.nodes;
      } catch (error) {
        throw apiErrorHandler('Error fetching systems', error);
      }
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const nextPageParam = lastPage && lastPage.length > 0 ? allPages.length * (args.first || 10) : undefined;

        return nextPageParam;
      },
      keepPreviousData: false,
      // @ts-ignore
      initialData: initialData
        ? ({ pages: [initialData], pageParams: [0] } as InfiniteData<System[]>)
        : { pages: [], pageParams: [0] },
      ...opts
    }
  );

  const systems: System[] = useMemo(() => (data?.pages.flat() as System[]) || [], [data]);
  const systemIds = useMemo(() => systems.map(({ id }) => id), [systems]);

  const {
    data: systemsStatsPaginated,
    fetchNextPage: fetchNextSystemMetricsPage,
    isFetching: isFetchingStats
  } = useSystemsMetricsStatsPaginated(systemIds, args.first);

  const systemsStats = useMemo(() => mergeSystemMetricsStatsData(systemsStatsPaginated), [systemsStatsPaginated]);

  return {
    data: useMemo(
      () =>
        systems.map(
          (system: System) =>
            ({
              ...system,
              productionYesterday: systemsStats?.productionYesterday?.[system.uuid] ?? 0,
              productionWeek: systemsStats?.productionWeek?.[system.uuid] ?? 0,
              productionMonth: systemsStats?.productionMonth?.[system.uuid] ?? 0,
              productionYear: systemsStats?.productionYear?.[system.uuid] ?? 0,
              productionLifetime: systemsStats?.productionLifetime?.[system.uuid] ?? 0,
              consumptionYesterday: systemsStats?.consumptionYesterday?.[system.uuid] ?? 0,
              consumptionWeek: systemsStats?.consumptionWeek?.[system.uuid] ?? 0,
              consumptionMonth: systemsStats?.consumptionMonth?.[system.uuid] ?? 0,
              consumptionYear: systemsStats?.consumptionYear?.[system.uuid] ?? 0,
              consumptionLifetime: systemsStats?.consumptionLifetime?.[system.uuid] ?? 0,
              peakPower: systemsStats?.peakPower?.[system.uuid] ?? 0
            }) as SystemWithStats
        ),
      [systems, systemsStats]
    ),
    isFetching: useMemo(() => isFetching || isFetchingStats, [isFetching, isFetchingStats]),
    fetchNextPage: async () => {
      await fetchNextPage();
      await fetchNextSystemMetricsPage();
    },
    hasNextPage
  };
};
