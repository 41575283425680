import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { ConnectSource } from '@generated/types/graphql';

type ExtendedConnectSource = ConnectSource & {
  proposals: ConnectSource[];
  contracts: ConnectSource[];
};

export const useConnectProject = (args: { projectId: number }) =>
  useQuery<ExtendedConnectSource>(
    [ReactQueryKey.ConnectIntegrationLog, 'useConnectProject', args],
    async () => {
      try {
        return (
          await postGraphql<{ connectSources: ExtendedConnectSource[] }>(
            gql`
              query EXTERNAL_PROJECT_QUERY($projectId: Int!) {
                connectSources(
                  condition: { projectId: $projectId, type: PROJECT }
                  first: 1
                  orderBy: [CREATED_AT_DESC]
                ) {
                  id
                  info
                  integrationId
                  externalId
                  integration {
                    type
                  }
                  proposals: connectSourcesBySourceProjectId(orderBy: UPDATED_AT_DESC, condition: { type: PROPOSAL }) {
                    id
                    info
                  }
                  contracts: connectSourcesBySourceProjectId(orderBy: UPDATED_AT_DESC, condition: { type: CONTRACT }) {
                    id
                    info
                  }
                }
              }
            `,
            args
          )
        ).connectSources[0];
      } catch (e) {
        throw apiErrorHandler('Error fetching connect sources for project', e);
      }
    },
    {
      initialData: null
    }
  );
