import React from 'react';
import { FileEventType } from '@enums';
import { File } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { FileUpIcon } from '@kit/ui/icons/FileUp';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { CommentFileThumbnail, CommentFileThumbnailContainer, FeedItemIconContainer } from './styled';
import { CommentItem } from './CommentItem';
import { BaseItemTemplate } from './BaseItemTemplate';
import { FeedItemProps } from './types';

export const FileName = ({ name }: { name: string }) => {
  if (name.length > 30) {
    return (
      <Tooltip title={name}>
        <b>
          {name.slice(0, 20)}...{name.slice(name.length - 4)}
        </b>
      </Tooltip>
    );
  }

  return <b>{name}</b>;
};

export const FileFeedItem = ({ item, context, contextEntityId, commentNumberedAnnotation }: FeedItemProps) => {
  const { openDrawer } = useDrawersContext();

  if (item.eventType === FileEventType.COMMENTED) {
    return (
      <CommentItem
        item={item}
        context={context}
        contextEntityId={contextEntityId}
        commentNumberedAnnotation={commentNumberedAnnotation}
      />
    );
  }

  if (item.eventType === FileEventType.FILE_UPLOADED && item.createdByContact) {
    const file = item.payload?.file;

    if (!file) {
      return null;
    }

    return (
      <BaseItemTemplate
        id={item.id}
        context={context}
        icon={
          <FeedItemIconContainer>
            <FileUpIcon color="#235DFF" size="16px" />
          </FeedItemIconContainer>
        }
        authorIcon={<ContactAvatar isPortalStatusShown={false} contact={item.createdByContact} />}
        date={item.createdAt}
        title={
          <>
            <b>{item.createdByContact.name}</b> uploaded document in Portal
          </>
        }
      >
        <CommentFileThumbnailContainer
          key={file.id}
          onClick={() => {
            openDrawer(DrawerEntity.FILE, item.payload.id, []);
            openDrawer(DrawerEntity.FILE, file.id, []);
          }}
        >
          {file?.metaData?.thumbnailUrl ? (
            <CommentFileThumbnail url={file.metaData.thumbnailUrl} />
          ) : (
            <File size="16px" color="#9C9CAA" />
          )}
        </CommentFileThumbnailContainer>
      </BaseItemTemplate>
    );
  }

  return null;
};
