import React from 'react';
import { EntityType } from '@enums';
import { ProjectFeedItem } from './ProjectFeedItem';
import { WorkOrderFeedItem } from './WorkOrderFeedItem';
import { FeedItemProps } from './types';
import { SmsFeedItem } from './SmsFeedItem';
import { LineItemFeedItem } from './LineItemFeedItem';
import { ReminderFeedItem } from './ReminderFeedItem';
import { WorkflowFeedItem } from './WorkflowFeedItem';
import { EmailFeedItem } from './EmailFeedItem';
import { FileFeedItem } from './FileFeedItem';
import { PortalMessageFeedItem } from './PortalMessageFeedItem';
import { CallFeedItem } from './CallFeedItem';
import { ActionFeedItem } from './ActionFeedItem';
import { SystemFeedItem } from './SystemFeedItem';

export const FeedItem = ({ item, context, contextEntityId, commentNumberedAnnotation }: FeedItemProps) => {
  switch (item.event) {
    case EntityType.PROJECT:
      return <ProjectFeedItem item={item} context={context} contextEntityId={contextEntityId} />;
    case EntityType.PORTAL:
      return <PortalMessageFeedItem item={item} context={context} />;
    case EntityType.TASK:
      return <WorkOrderFeedItem item={item} context={context} contextEntityId={contextEntityId} />;
    case EntityType.REMINDER:
      return <ReminderFeedItem item={item} context={context} contextEntityId={contextEntityId} />;
    case EntityType.SMS:
      return <SmsFeedItem item={item} context={context} contextEntityId={contextEntityId} />;
    case EntityType.LINE_ITEM:
      return <LineItemFeedItem item={item} context={context} />;
    case EntityType.WORKFLOW:
      return <WorkflowFeedItem item={item} context={context} contextEntityId={contextEntityId} />;
    case EntityType.EMAIL:
      return <EmailFeedItem item={item} context={context} contextEntityId={contextEntityId} />;
    case EntityType.FILE:
      return (
        <FileFeedItem
          item={item}
          context={context}
          contextEntityId={contextEntityId}
          commentNumberedAnnotation={commentNumberedAnnotation}
        />
      );
    case EntityType.CALL:
      return <CallFeedItem item={item} context={context} contextEntityId={contextEntityId} />;
    case EntityType.ACTION:
      return <ActionFeedItem item={item} context={context} contextEntityId={contextEntityId} />;
    case EntityType.SYSTEM:
      return <SystemFeedItem item={item} context={context} contextEntityId={contextEntityId} />;
    default:
      return null;
  }
};
