import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { connectApi } from '@services/api/connectApi';
import { errorHandler } from '@services/api/helpers';

export const useConnectMappingOptions = (integrationId: number) =>
  useQuery({
    queryKey: [ReactQueryKey.ConnectIntegrationSchema, integrationId],
    queryFn: async () => {
      try {
        return (await connectApi.getColumnsMappingOptions(integrationId)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    initialData: []
  });
