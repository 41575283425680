import { colors, fonts } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  thead {
    th {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font: ${(props) => props.theme.typefaces.text};
      color: #828D9A;
      height: 40px;
      text-align: left;
      padding: 12px 16px 12px 16px;
      text-transform: uppercase;
    }
  }

  tbody {
    tr {
      height: 60px;
      background-color: ${colors.white};
    }

    td {
      overflow: hidden;
      word-break: break-word;
      font: ${(props) => props.theme.typefaces.pagination};
      padding: 12px 16px 12px 16px;
      text-align: left;
      border-bottom: 1px solid #DFDFE8;
    }

    td:first-child {
      border-left: 1px solid #DFDFE8;
    }

    td:last-child {
      border-right: 1px solid #DFDFE8;
    }

    tr:first-child td {
      border-top: 1px solid #DFDFE8;
    }

    tr:last-child td {
      border-bottom: 1px solid #DFDFE8;
    }

    tr:first-child td:first-child {
      border-top-left-radius: 8px;
    }

    tr:first-child td:last-child {
      border-top-right-radius: 8px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 8px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 8px;
    }
  }
`;

export const PropertyPath = styled.div`
  font: 400 14px / 18px ${fonts.primary} 
`;

export const NewPropertyOption = styled.div`
  display: flex;
  gap: 4px;
  align-content: center;
  font: 500 12px/1.25 ${fonts.primary}
  color: ${colors.green}
`;

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;