import { ReactQueryKey } from '@enums';
import { FeedInboxFilter, FeedInboxesConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { DeepPartial } from 'redux';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector, useAuth } from '..';

const fetchUnreadNotificationsCount = async ({ userId, companyId }: { userId: number; companyId: number }) => {
  const feedFilter: DeepPartial<FeedInboxFilter> = {
    userId: { equalTo: userId },
    read: { equalTo: false },
    feedExists: true,
    feed: {
      companyId: { equalTo: companyId }
    }
  };

  try {
    const {
      feedInboxesConnection: { totalCount }
    } = await postGraphql<{ feedInboxesConnection: FeedInboxesConnection }>(
      gql`
        query UNREAD_NOTIFICATION_COUNT_QUERY($filter: FeedInboxFilter) {
          feedInboxesConnection(filter: $filter) {
            totalCount
          }
        }
      `,
      { filter: feedFilter },
      undefined,
      { useReplica: true }
    );

    return totalCount;
  } catch (e) {
    throw apiErrorHandler(`Error fetching notifications count`, e);
  }
};

export const useUnreadNotificationCount = () => {
  const { user } = useAuth();

  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<number>(
    [ReactQueryKey.InboxNotificationsUnreadCounter, companyId, user.userId],
    () => fetchUnreadNotificationsCount({ userId: user.userId, companyId }),
    {
      enabled: user.userId !== 0
    }
  );
};
