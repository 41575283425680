import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import { connectApi } from '@services/api/connectApi';
import { ReactQueryKey } from '@enums';

export const useDeleteColumnMapping = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<void, Error, { integrationId: number; columnId: number }>(
    async ({ integrationId, columnId }) => {
      try {
        await connectApi.deleteColumnMapping(integrationId, columnId);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess(`Integration deleted successfully`);
        queryClient.invalidateQueries([ReactQueryKey.ConnectIntegration]);
      }
    }
  );
};
