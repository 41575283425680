import styled, { css } from 'styled-components';
import { fonts } from '@styles';
import { Link } from '@reach/router';

import { ButtonProps, ButtonSize, ButtonVariant, IconButtonProps } from './types';

export const Container = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ isBlock }) => (isBlock ? '100%' : 'auto')};
  gap: 4px;
  min-width: 70px;
  font-weight: 500;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition:
    background-color 0.3s,
    color 0.3s,
    border-color 0.3s;
  padding: 0px 8px;
  text-transform: ${({ isUpperCase = true }) => (isUpperCase ? 'uppercase' : 'none')};
  border: 1px solid transparent;
  font-family: ${fonts.primary};

  svg {
    flex-shrink: 0;
  }

  ${({ size = ButtonSize.Default }) => {
    switch (size) {
      case ButtonSize.Small:
        return css`
          font-size: 12px;
          padding: 0px 4px;
          height: 24px;
        `;
      case ButtonSize.Large:
        return css`
          font-size: 14px;
          padding: 0px 16px;
          letter-spacing: 0.7px;
          height: 40px;
        `;
      default:
        return css`
          font-size: 12px;
          padding: 0px 8px;
          letter-spacing: 0.6px;
          height: 32px;
        `;
    }
  }};

  ${(props) => {
    switch (props.variant) {
      case ButtonVariant.Primary:
        return css`
          background-color: #009688;
          color: #ffffff;

          &:hover {
            background-color: #00635a;
          }

          &:active {
            background-color: #004a43;
          }

          &:focus {
            outline: none;
            border: 1px solid #000;
          }

          &:disabled {
            background-color: #dfdfe8;
            color: #fff;
            cursor: not-allowed;
          }
        `;

      case ButtonVariant.Secondary:
        return css`
          background-color: #dfdfe8;

          color: #1d1d35;

          &:hover {
            background-color: #c8c8d3;
          }

          &:active {
            background-color: #c8c8d3;
          }

          &:focus {
            outline: none;
            border: 1px solid #1d1d35;
          }

          &:disabled {
            background-color: #dfdfe8;
            color: #fff;
            cursor: not-allowed;
          }
        `;

      case ButtonVariant.Danger:
        return css`
          background-color: #d54855;
          color: #ffffff;

          &:hover {
            background-color: #d54855;
          }

          &:active {
            background-color: #d54855;
          }

          &:focus {
            outline: none;
            border: 1px solid #000;
          }

          &:disabled {
            background-color: #dfdfe8;
            color: #fff;
            cursor: not-allowed;
          }
        `;
      case ButtonVariant.Flat:
        return css`
          ${props.size === ButtonSize.Small &&
          css`
            padding: 0px;
            min-width: auto;
            height: 16px;
          `};
          color: #009688;
          background-color: transparent;

          &:hover {
            color: #00635a;
            background-color: #e3f6f4;
          }

          &:active {
            color: #004a43;
            background-color: #e3f6f4;
          }

          &:focus {
            outline: none;
            border: 1px solid #1d1d35;
          }

          &:disabled {
            color: #c8c8d3;
            cursor: not-allowed;
          }
        `;

      case ButtonVariant.Outlined:
        return css`
          color: #1d1d35;
          background-color: transparent;
          border: 1px solid #dfdfe8;

          &:hover {
            border-color: #99d5cf;
          }

          &:active {
            border-color: #99d5cf;
            background-color: #e3f6f4;
          }

          &:focus {
            outline: none;
            border: 1px solid #99d5cf;
          }

          &:disabled {
            color: #c8c8d3;
            cursor: not-allowed;
          }
        `;

      default:
        return null;
    }
  }}
`;

export const IconButton = styled(Container)<IconButtonProps>`
  padding: 0;
  justify-content: center;
  ${({ size = ButtonSize.Default }) => {
    switch (size) {
      case ButtonSize.Small:
        return css`
          height: 24px;
          width: 24px;
          min-width: 24px;
        `;
      case ButtonSize.Large:
        return css`
          height: 40px;
          width: 40px;
          min-width: 40px;
        `;
      default:
        return css`
          height: 32px;
          width: 32px;
          min-width: 32px;
        `;
    }
  }};
`;

const linkStyles = `
color: #235dff;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    font-size:14px;
    font-weight: 400;

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:link {
      color: #235dff;
    }

`;

export const LinkContainer = styled(Link)`
  ${linkStyles}
`;

export const Anchor = styled.a`
  ${linkStyles}
`;
