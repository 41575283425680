import React, { useCallback, useRef } from 'react';

import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { ContextMenuPopover, MenuItemContainer } from './styled';

export interface MenuItem {
  icon?: React.ReactNode;
  title: string;
  onClick: () => void;
}

interface Props {
  items: MenuItem[];
  children?: React.ReactNode;
  position?: 'right' | 'left';
  point?: { x: number; y: number } | null;
  onClose?: () => void;
}

const DEFAULT_TRIGGER = <MoreVerticalIcon size="16px" color="#9C9CAA" />;

export const ContextMenu = ({ point, position = 'right', items, children = DEFAULT_TRIGGER, onClose }: Props) => {
  const popoverRef = useRef(null);

  const handleItemClick = useCallback(
    (item: MenuItem) => (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      item.onClick();

      popoverRef.current?.close();
    },
    []
  );

  return (
    <ContextMenuPopover
      open={point ? true : undefined}
      anchorReference={point ? 'anchorPosition' : undefined}
      anchorPosition={point ? { top: point.y, left: point.x } : undefined}
      ref={popoverRef}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: position
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: position
      }}
      onClose={onClose}
      content={items.map((item) => (
        <MenuItemContainer key={item.title} onClick={handleItemClick(item)}>
          {item.icon}
          <div>{item.title}</div>
        </MenuItemContainer>
      ))}
      onContextMenu={
        point
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();

              onClose?.();
            }
          : undefined
      }
    >
      {children}
    </ContextMenuPopover>
  );
};
