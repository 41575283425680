import React, { useMemo, useState } from 'react';
import { useConnectProject } from '@hooks/workspace/connectIntegration';
import { ExternalLink, Users, Download } from 'react-feather';
import { ConnectIntegrationType } from '@features/Platform/Integrations';
import { colors } from '@styles';
import { Badge } from '@kit/ui/Badge';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { enumToHuman, parseUtcDate, formatDate } from '@utils';
import { ConnectIntegrationBrand } from '@features/Platform/Integrations/Common/ConnectIntegrationBrand/ConnectIntegrationBrand';
import { Cell, CellBotton, CellTop, List, Row, Tab, Tabs } from './styled';
import { Widget, WidgetHeader, WidgetIcon, WidgetTitle } from '../styled';

interface Props {
  projectId: number;
  isFullWidth?: boolean;
  title?: string;
}

const dateFormat = 'MM/DD/YY hh:mma';

export const Sales: React.FC<Props> = ({ title = 'Sales', projectId, isFullWidth = false }) => {
  const [activeTab, setActiveTab] = useState('projects');

  const { data: project } = useConnectProject({ projectId });

  const integrationType = project?.integration.type as ConnectIntegrationType;

  const tabs = useMemo(
    () =>
      ['projects']
        .concat(project?.proposals.length > 0 ? ['proposals'] : [])
        .concat(project?.contracts.length > 0 ? ['contracts'] : []),
    [project]
  );

  return (
    <Widget isFullWidth={isFullWidth}>
      <WidgetHeader>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#ECE3FF">
            <Users size="16px" color="#7256AC" />
          </WidgetIcon>
          {title}
        </WidgetTitle>
      </WidgetHeader>

      <Tabs>
        {tabs.map((tab) => (
          <Tab key={tab} onClick={() => setActiveTab(tab)} isActive={activeTab === tab}>
            {tab}
          </Tab>
        ))}
      </Tabs>

      {activeTab === 'projects' && (
        <List>
          {project && (
            <Row key={project.id}>
              <Cell>
                <div>{project.info.name}</div>
                <CellBotton>{formatDate(parseUtcDate(project.info.updatedAt), dateFormat)}</CellBotton>
              </Cell>
              <Cell style={{ alignItems: 'center' }}>
                <Badge color={colors.black} bgColor="#DFDFE8">
                  {enumToHuman(project.info.status) || '<Unknown>'}
                </Badge>
                <div>&nbsp;</div>
              </Cell>
              <Cell>
                <CellTop>
                  <ConnectIntegrationBrand type={integrationType} />
                  <a href={project.info.url} target="_blank" rel="noreferrer">
                    <ExternalLink size={16} color={colors.blue} />
                  </a>
                </CellTop>
                <CellBotton>ID: {project.info.id}</CellBotton>
              </Cell>
            </Row>
          )}
        </List>
      )}

      {activeTab === 'proposals' && (
        <List>
          {(project?.proposals || []).map((proposal) => (
            <Row key={proposal.id}>
              <Cell>
                <div>{proposal.info.name}</div>
                <CellBotton>{formatDate(parseUtcDate(proposal.info.updatedAt), dateFormat)}</CellBotton>
              </Cell>
              <Cell style={{ alignItems: 'center' }}>
                <Badge color={colors.black} bgColor="#DFDFE8">
                  {enumToHuman(proposal.info.status) || '<Unknown>'}
                </Badge>
                <div>
                  {proposal.info.fileUrl && (
                    <Button
                      size={ButtonSize.Small}
                      isUpperCase={false}
                      variant={ButtonVariant.Link}
                      target="_blank"
                      to={proposal.info.fileUrl}
                    >
                      <Download size={16} />
                      Download
                    </Button>
                  )}
                </div>
              </Cell>
              <Cell>
                <CellTop>
                  <ConnectIntegrationBrand type={integrationType} />
                  <a href={proposal.info.url} target="_blank" rel="noreferrer">
                    <ExternalLink size={16} color={colors.blue} />
                  </a>
                </CellTop>
                <CellBotton>ID: {proposal.info.id}</CellBotton>
              </Cell>
            </Row>
          ))}
        </List>
      )}

      {activeTab === 'contracts' && (
        <List>
          {(project?.contracts || []).map((contract) => (
            <Row key={contract.id}>
              <Cell>
                <div>{contract.info.name}</div>
                <CellBotton>{formatDate(parseUtcDate(contract.info.updatedAt), dateFormat)}</CellBotton>
              </Cell>
              <Cell style={{ alignItems: 'center' }}>
                <Badge color={colors.black} bgColor="#DFDFE8">
                  {enumToHuman(contract.info.status) || '<Unknown>'}
                </Badge>
                <div>
                  <div>
                    {contract.info.fileUrl && (
                      <Button
                        size={ButtonSize.Small}
                        isUpperCase={false}
                        variant={ButtonVariant.Link}
                        target="_blank"
                        to={contract.info.fileUrl}
                      >
                        <Download size={16} />
                        Download
                      </Button>
                    )}
                  </div>
                </div>
              </Cell>
              <Cell>
                <CellTop>
                  <ConnectIntegrationBrand type={integrationType} />
                  {contract.info.url && (
                    <a href={contract.info.url} target="_blank" rel="noreferrer">
                      <ExternalLink size={16} color={colors.blue} />
                    </a>
                  )}
                </CellTop>
                <CellBotton>ID: {contract.info.id}</CellBotton>
              </Cell>
            </Row>
          ))}
        </List>
      )}
    </Widget>
  );
};
