import { Link } from '@reach/router';
import React from 'react';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Danger = 'danger',
  Flat = 'flat',
  Link = 'link',
  Outlined = 'outlined'
}

export enum ButtonSize {
  Default = 'default',
  Small = 'small',
  Large = 'large'
}

interface BaseButtonProps {
  size?: ButtonSize;
  isUpperCase?: boolean;
  children?: React.ReactNode;
}

export interface RegularButtonProps extends BaseButtonProps, React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant:
    | ButtonVariant.Danger
    | ButtonVariant.Flat
    | ButtonVariant.Primary
    | ButtonVariant.Secondary
    | ButtonVariant.Outlined;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  isBlock?: boolean;
}

export interface LinkButtonProps extends BaseButtonProps, React.ComponentProps<typeof Link> {
  variant: ButtonVariant.Link;
  type?: never;
}

export type ButtonProps = RegularButtonProps | LinkButtonProps;

export type IconButtonProps = ButtonProps & {
  'area-label': string;
};
