import { ProjectDetail } from '@types';
import { isString } from 'lodash';

export const getTimeInStageInDays = (record: ProjectDetail) => {
  const timeInStageRangeProperty = record.projectPropertiesValues?.find(
    (prop) => prop.column?.mappedName === 'timeInStageRange'
  );

  return timeInStageRangeProperty?.timeRangeDaysValue;
};

export const getSlaStageColor = (record: ProjectDetail): 'RED' | 'YELLOW' | 'NON_VIOLATED' => {
  const slaStatusProperty = record.projectPropertiesValues?.find((prop) => prop.column?.mappedName === 'slaStatus');

  return slaStatusProperty?.dropdownValue[0] as 'RED' | 'YELLOW' | 'NON_VIOLATED';
};

export const parseLocation = (location: string | string[]): { lat: number; lng: number } | null => {
  if (Array.isArray(location) && location.length === 1 && isString(location[0])) {
    const [lat, lng] = location[0].split(',').map((pos) => parseFloat(pos)) as [number, number];

    return { lat, lng };
  }

  if (isString(location)) {
    const [lat, lng] = location.split(',').map((pos) => parseFloat(pos)) as [number, number];

    return { lat, lng };
  }

  return null;
};
