import { useMutation } from 'react-query';
import { useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import { flatfileApi } from '@services/api/flatfileApi';

export const useMakeFlatfileSpace = () => {
  const { showSuccess } = useToast();

  return useMutation<string, Error>(
    async () => {
      try {
        return (await flatfileApi.makeFlatfileSpace()).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess(`Flatfile space created successfully`);
      }
    }
  );
};
