import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { connectApi } from '@services/api/connectApi';
import { errorHandler } from '@services/api/helpers';

export const useConnectTriggerOptions = (integrationId: number) =>
  useQuery({
    queryKey: [ReactQueryKey.ConnectIntegrationTrigger, integrationId],
    queryFn: async () => {
      try {
        return (await connectApi.getTriggerOptions(integrationId)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    initialData: {}
  });
