import React, { FC, useCallback, useMemo, useState } from 'react';
import { Property, RecordType } from '@types';
import { useCompanyProperties, usePropertyGroups } from '@hooks';
import { Container, NewPropertyOption, PropertyPath, SubTitle, Table } from './styled';
import { valuePathToName } from './constants';
import { ConnectColumnMapping } from '@generated/types/graphql';
import { CreatableSelect, Select } from '@kit/ui/Select';
import {
  useConnectMappingOptions,
  useCreateColumnMapping,
  useDeleteColumnMapping
} from '@hooks/workspace/connectIntegration';
import { Button, ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { useModal } from '@common/PromiseModal';
import { PropertyForm } from '@features/Platform/Properties/List/PropertyForm';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { ConnectIntegrationName, ConnectIntegrationType, MappingOption } from '@features/Platform/Integrations';

interface HistoryProps {
  integrationId: number;
  integrationType: ConnectIntegrationType;
  mappings: ConnectColumnMapping[];
}

export const CustomPropertiesMapping: FC<HistoryProps> = ({ integrationId, integrationType, mappings }) => {
  const { data: mappingOptions } = useConnectMappingOptions(integrationId);
  const formattedMappingOptions = useMemo(
    () =>
      mappingOptions.map((option) => ({
        ...option,
        name: valuePathToName(option.path)
      })),
    [mappingOptions]
  );

  const { editableColumns } = useCompanyProperties({ recordType: RecordType.PROJECT });
  const { data: groups } = usePropertyGroups({ scope: RecordType.PROJECT, fullAccess: true });

  const [showNewMapping, setShowNewMapping] = useState(false);

  const [selectedMappingOption, setSelectedMappingOption] = useState<(MappingOption & { name: string }) | null>(null);

  const availableColumns = useMemo(
    () =>
      editableColumns.filter(
        (column) =>
          column.type === selectedMappingOption?.column.type &&
          !mappings.some((mapping) => mapping.column.id === (column.id as number))
      ),
    [editableColumns, mappings, selectedMappingOption]
  );

  const { mutateAsync: createMapping, isLoading: isCreateMappingLoading } = useCreateColumnMapping();
  const { openModal } = useModal();
  const handleCreateProperty = useCallback(async () => {
    await openModal<Property>(
      ({ onClose }) => (
        <PropertyForm
          scope={RecordType.PROJECT}
          onClose={onClose}
          groups={groups}
          lockedValues={selectedMappingOption?.column}
        />
      ),
      {
        title: `Create a Property to map "${selectedMappingOption?.name}"`
      }
    );
  }, [openModal, groups, selectedMappingOption]);

  const handleCreateMapping = async (column: Property) => {
    if (!column.id) {
      return; // ignore intermediate dialog states while a potential new property is being created
    }

    await createMapping({
      integrationId,
      dto: {
        valuePath: selectedMappingOption.path,
        columnId: column.id as number
      }
    });

    setSelectedMappingOption(null);
    setShowNewMapping(false);
  };

  const { mutateAsync: deleteMapping } = useDeleteColumnMapping();
  const handleDeleteColumn = async (columnId: number) => {
    await deleteMapping({ integrationId, columnId });
  };

  return (
    <Container>
      <SubTitle>Custom mapping</SubTitle>

      <Table>
        <thead>
          <tr>
            <th style={{ width: '45%' }}>{ConnectIntegrationName[integrationType]} Field</th>
            <th style={{ width: '45%' }}>Coperniq Property</th>
            <th style={{ width: '10%' }} />
          </tr>
        </thead>
        <tbody>
          {mappings
            .filter((mapping) => !mapping.standard)
            .map((mapping) => (
              <tr>
                <td style={{ width: '45%' }}>{valuePathToName(mapping.valuePath)}</td>
                <td style={{ width: '45%' }}>
                  <PropertyPath>{mapping.column.name}</PropertyPath>
                </td>
                <td>
                  <IconButton
                    variant={ButtonVariant.Flat}
                    size={ButtonSize.Small}
                    onClick={() => handleDeleteColumn(mapping.column.id)}
                  >
                    <TrashIcon size={16} color="#9C9CAA" />
                  </IconButton>
                </td>
              </tr>
            ))}

          {showNewMapping && (
            <tr>
              <td style={{ width: '50%' }}>
                <Select
                  options={formattedMappingOptions}
                  value={selectedMappingOption}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, option) => setSelectedMappingOption(option)}
                  disabled={isCreateMappingLoading}
                  placeholder="Select a field to map from..."
                />
              </td>
              <td style={{ width: '50%' }}>
                <CreatableSelect
                  options={availableColumns}
                  getOptionLabel={(column) => column.name}
                  onChange={(_, column) => handleCreateMapping(column)}
                  disabled={isCreateMappingLoading || !selectedMappingOption}
                  createButtonText={() => (
                    <NewPropertyOption>
                      <Plus size="16px" /> Create property
                    </NewPropertyOption>
                  )}
                  buildNewOption={handleCreateProperty}
                  placeholder="...and select a Property to map to"
                />
              </td>
              <td>
                <IconButton
                  variant={ButtonVariant.Flat}
                  size={ButtonSize.Small}
                  onClick={() => setShowNewMapping(false)}
                >
                  <TrashIcon size={16} color="#9C9CAA" />
                </IconButton>
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {!showNewMapping && (
        <div>
          <Button variant={ButtonVariant.Flat} size={ButtonSize.Large} onClick={() => setShowNewMapping(true)}>
            <Plus size="16px" /> Property
          </Button>
        </div>
      )}
    </Container>
  );
};
