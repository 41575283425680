import { capitalize, curry } from 'lodash';
import { isBrowser } from '@utils/utils';
import qs from 'querystring';
import { QueryParamsEnum } from '@hooks/useQueryParam';

export const capitalizeFirstLetter = (value: string = '') =>
  value.charAt(0).toUpperCase() + value.toLowerCase().slice(1);

export const trimWithDots = (value: string, trimLength: number = 2000) =>
  `${value?.substring(0, trimLength)}${value?.length > trimLength ? '…' : ''}`;

export const extractTextFromHtml = (html: string) => {
  if (!isBrowser) {
    return '';
  }

  if (!html) {
    return html;
  }

  const doc = document.implementation.createHTMLDocument('');
  doc.open();
  doc.write(html);
  doc.close();

  const { body } = doc;

  return body.textContent || body.innerText;
};

const WORKSPACE_IN_URL_PATTERN =
  /(\d+)\/(projects|workspace|analytics|contacts|clients|requests|inbox|dispatcher-new|scheduler|list)/;
const RECORD_IN_URL_PATTERN = /\d+\/(projects|clients|contacts|accounts|deals|requests)\/(\d+)/;

const INBOX_PATTERN = /\d+\/inbox/;

export const parseWorkspaceIdFromUrl = ($url?: string) => {
  if (!isBrowser) {
    return null;
  }

  const url = $url || window.location.pathname;
  const matched = url.match(WORKSPACE_IN_URL_PATTERN)?.[1];

  return matched ? +matched : null;
};

export const parseNotificationIdFromPathname = ($pathname?: string) => {
  if (!isBrowser) {
    return null;
  }

  const pathname = $pathname || window.location.pathname;
  const matched = pathname.match(/notifications\/(\d+)/)?.[1];

  return matched ? +matched : null;
};

export const parseRecordIdFromUrl = (url?: string, search?: string) => {
  if (!url) {
    return null;
  }

  const isInbox = INBOX_PATTERN.test(url);

  if (isInbox) {
    if (!search) {
      return null;
    }

    const query = qs.parse(search.replace('?', ''));

    if (query[QueryParamsEnum.InboxProjectId]) {
      return +query[QueryParamsEnum.InboxProjectId];
    }

    return null;
  }

  const matched = url.match(RECORD_IN_URL_PATTERN)?.[2];

  return matched ? +matched : null;
};

export const pluralize = curry(([noun, pluralForm]: string[], count: number) => (count === 1 ? noun : pluralForm));

export const integerIfFinite = (value: any) => {
  const number = parseInt(value, 10);

  return Number.isNaN(number) ? undefined : number;
};

export const stripHtml = (html: string) => {
  if (!html) {
    return '';
  }

  // Remove HTML tags
  let strippedString = html.replace(/<[^>]+>/g, ' ');

  // Replace line breaks with spaces
  strippedString = strippedString.replace(/\n/g, ' ');

  // Remove consecutive spaces
  strippedString = strippedString.replace(/\s\s+/g, ' ');

  return strippedString.trim();
};

export const splitByComma = (value: string) => {
  if (!value) {
    return [];
  }

  return value.split(',').map((item) => item.trim());
};

/**
 * `AURORA_SOLAR` -> `Aurora Solar`
 */
export const enumToHuman = (enumValue: string) => (enumValue || '').split('_').map(capitalize).join(' ');
