import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useToggle } from 'react-use';
import { delay, filter, identity } from 'lodash/fp';
import { useRoutes } from '@hooks/useRoutes';
import { ColumnAccessType, Property, RecordType, Project } from '@types';
import { ReactQueryKey, UserRole } from '@enums';
import columnApi from '@services/api/columnApi';
import { apiErrorHandler, invalidateQueriesBy } from '@utils';
import { applyOrder, isEditable, isNumeric } from '@utils/properties';
import { VIRTUAL_FILTER_PROPERTIES } from '@components/Project/ProjectHeader/Filters/helpers';
import { useAuth } from '@hooks/useAuth';
import { useTeams } from '@hooks/useTeams';
import { useToast } from '@hooks/useToast';
import projectApi from '@services/api/projectApi';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from './store';

const excludes = [
  'geoLocation',
  'groupId',
  'imageUrl',
  'isActive',
  'isCompleted',
  'companyId',
  'updatedAt',
  'createdBy',
  'template',
  'timeInStageRange'
];

const exclude = (results: Property[], namesToExclude: string[]) =>
  results.filter((r) => r.isAdditional || !namesToExclude.includes(r.mappedName || ''));

// record-detail only columns
export const PORTFOLIO_ONLY: Property['id'][] = [];

const DEFAULT_EMPTY: Property[] = [];

export const useCompanyPropertiesWithoutRouter = (
  companyId: number,
  props: { recordType?: RecordType; enabled?: boolean; fullAccess?: boolean }
) => {
  const queryClient = useQueryClient();
  const { enabled: enabledFromProps, recordType, fullAccess } = props || {};

  const { role, user } = useAuth(companyId);

  const { data: teams } = useTeams(companyId);

  const userTeams = useMemo(
    () => teams?.filter((team) => team.teamUsers.some((u) => u.id === user?.userId)) ?? [],
    [teams, user]
  );

  const fetchQuery = useQuery(
    [ReactQueryKey.CompanyProperties, companyId],
    async () => {
      try {
        const { data } = await columnApi.fetchColumns({
          companyId,
          fetchAll: true
        });

        return data.results;
      } catch (error) {
        throw apiErrorHandler('exception on getting company properties', error);
      }
    },
    {
      enabled: enabledFromProps ?? !!companyId,
      staleTime: 1000 * 3600 * 24,
      refetchOnMount: false
    }
  );

  /**
   * Unfiltered, prefer filtered `columns` instead
   */
  const allProperties = useMemo(() => {
    if (!fetchQuery.data) {
      return DEFAULT_EMPTY;
    }

    return recordType
      ? applyOrder(companyId, recordType, exclude(fetchQuery.data, excludes))
      : exclude(fetchQuery.data, excludes);
  }, [recordType, fetchQuery.data, companyId]);

  /**
   * Unfiltered, prefer filtered `scopeToColumns` instead
   */
  const scopeToAllColumns = useMemo(
    () =>
      Object.values(RecordType).reduce(
        (acc, rType) => ({
          ...acc,
          [rType]: allProperties.filter((c) => c.scope?.includes(rType))
        }),
        {}
      ) as { [recordType in RecordType]: Property[] },
    [allProperties]
  );

  const accessCheckMiddleware = useMemo(
    () =>
      fullAccess
        ? filter<Property>(() => {
            return role?.name === UserRole.ADMIN;
          })
        : identity,
    [fullAccess, role?.name]
  );

  const columns = useMemo(
    () =>
      accessCheckMiddleware(allProperties).filter(
        (column) =>
          !column.access?.length ||
          column.access.some((access) => {
            switch (access.type) {
              case ColumnAccessType.team:
                return userTeams?.some((team) => team.id === access.teamId);
              case ColumnAccessType.role:
                return role?.id === access.roleId;
              default:
                throw new Error(`Unrecognized column access type ${access.type}`);
            }
          })
      ),
    [accessCheckMiddleware, allProperties, role?.id, userTeams]
  );

  const editableColumns = useMemo(() => (columns || []).filter(isEditable), [columns]);

  const scopeToColumns = useMemo(
    () =>
      Object.values(RecordType).reduce(
        (acc, rType) => ({
          ...acc,
          [rType]: (columns || []).filter((c) => c.scope?.includes(rType))
        }),
        {}
      ) as { [recordType in RecordType]: Property[] },
    [columns]
  );

  const scopeToColumnsById = useMemo(
    () =>
      Object.values(RecordType).reduce(
        (acc, rType) => ({
          ...acc,
          [rType]: scopeToColumns[rType].reduce(
            (acc, property) => {
              acc[property.id as number] = property;

              return acc;
            },
            {} as Record<number, Property>
          )
        }),
        {}
      ) as { [recordType in RecordType]: Record<number, Property> },
    [scopeToColumns]
  );

  const scopeToEditableColumns = useMemo(
    () =>
      Object.values(RecordType).reduce(
        (acc, rType) => ({
          ...acc,
          [rType]: scopeToColumns[rType].filter(isEditable)
        }),
        {}
      ) as { [recordType in RecordType]: Property[] },
    [scopeToColumns]
  );

  const filterColumns = useMemo(() => [...(columns ?? []), ...VIRTUAL_FILTER_PROPERTIES], [columns]);

  const refetch = useCallback(() => {
    invalidateQueriesBy(queryClient, [ReactQueryKey.CompanyProperties]);
  }, [queryClient]);

  return {
    fetchQuery,
    allProperties,
    scopeToAllColumns,
    properties: columns,
    columns,
    editableColumns,
    scopeToColumns,
    scopeToEditableColumns,
    filterColumns,
    refetch,
    scopeToColumnsById
  };
};

export const useCompanyProperties = (props?: { recordType?: RecordType; enabled?: boolean; fullAccess?: boolean }) => {
  const { enabled: enabledFromProps, recordType: recordTypeFromProps, fullAccess } = props || {};
  const { recordType: $recordType, companyId } = useRoutes();
  const recordType = recordTypeFromProps || $recordType;

  return useCompanyPropertiesWithoutRouter(companyId, { enabled: enabledFromProps, recordType, fullAccess });
};

export type UpdatePropertyMutationProps = {
  projectId: number;
  property: Property;
  newValue: any;
};

export const useCompanyPropertiesMutations = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);

  const [isOnUpdate, setIsOnUpdate] = useToggle(false);

  const createMutation = useMutation<Property, Error, Property>(
    async (dto) => {
      try {
        return (await columnApi.createColumn(dto, { companyId })).data;
      } catch (e) {
        throw apiErrorHandler('Error on creating property', e);
      }
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries([ReactQueryKey.CompanyProperties]);

        showSuccess(`Property <b>${data.name}</b> successfully created`);
      }
    }
  );

  const deleteMutation = useMutation<void, Error, number>(
    async (id) => {
      try {
        await columnApi.deleteColumn(id, { companyId });
      } catch (e) {
        throw apiErrorHandler('Error on deleting property', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.CompanyProperties]);

        showSuccess('Property successfully deleted');
      }
    }
  );

  const updateMutation = useMutation<Property, Error, Property>(
    async (dto) => {
      try {
        return (await columnApi.updateColumn(dto, { companyId })).data;
      } catch (e) {
        throw apiErrorHandler('Error on updating property', e);
      }
    },
    {
      onSuccess: (data) => {
        invalidateQueriesBy(queryClient, [ReactQueryKey.CompanyProperties]);

        showSuccess(`Property <b>${data.name}</b> successfully updated`);
      }
    }
  );

  const updatePropertyMutation = useMutation<Project, Error, UpdatePropertyMutationProps>(
    async ({ property, projectId, newValue }) => {
      let payload: any = {
        additional: {
          [property.id]: newValue
        }
      };

      if (!property.isAdditional) {
        if (newValue != null && isNumeric(property)) {
          payload = {
            [property.mappedName!]: +newValue
          };
        } else {
          payload = {
            [property.mappedName!]: newValue
          };
        }
      }

      setIsOnUpdate(true);

      try {
        return (await projectApi.updateProject(projectId, payload)).data;
      } finally {
        delay(300, () => setIsOnUpdate(false));
      }
    },
    {
      onSuccess: (_data, { projectId, property }) => {
        invalidateQueriesBy(queryClient, [`useRecord-${projectId}`]);
        queryClient.invalidateQueries([ReactQueryKey.RecordDetail, projectId]);
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceProjects]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectsListInitialGroupData]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectsByIds]);
        queryClient.invalidateQueries([ReactQueryKey.ClientsListInitialGroupData]);
        queryClient.invalidateQueries([ReactQueryKey.ClientsByIds]);
        queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
        queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        invalidateQueriesBy(queryClient, [ReactQueryKey.CompanyProperties]);
        queryClient.invalidateQueries([ReactQueryKey.ProjectActivity]);

        showSuccess(`Property <b>${property.name || property.mappedName}</b> successfully updated`);
      }
    }
  );

  return {
    create: createMutation,
    update: updateMutation,
    remove: deleteMutation,
    updateProjectProperty: updatePropertyMutation,
    isOnUpdate
  };
};
