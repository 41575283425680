import React, { useCallback, useMemo, useState } from 'react';
import { FieldPath, FieldValues } from 'react-hook-form';
import { Select } from '@kit/ui/Select';
import { upperFirst } from 'lodash';
import { useUpdateEffect } from '@react-hookz/web';
import { usePlaces } from '@hooks/usePlaces';
import { Suggestion } from 'use-places-autocomplete';
import { FormField } from '../../FormField';
import { FormControl, FormInputPropsToOmit } from '../../types';
import { useControllerWithValidation } from '../../useControllerWithValidation';
import { Option } from './styled';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<React.ComponentProps<typeof Select>, FormInputPropsToOmit | 'options'>,
    FormControl<TFieldValues, TName> {
  predefinedOptions?: string[];
}

export const toSuggestion = (address: string): Suggestion => ({
  description: address,
  structured_formatting: {
    main_text: upperFirst(address)
  }
});

const toDefaultSuggestion = (searchValue: string): Suggestion => ({
  description: searchValue,
  structured_formatting: {
    main_text: upperFirst(searchValue),
    secondary_text: 'Use this address as is'
  }
});

export const toAddress = (suggestion: Suggestion): string => suggestion?.description || '';

export const AddressField = <TFieldValues extends FieldValues, TName extends FieldPath<TFieldValues>>({
  label,
  description,
  name,
  control,
  clearOnUnmount,
  predefinedOptions = [],
  ...inputProps
}: Props<TFieldValues, TName>) => {
  const {
    field: { value: fieldValue, onChange, ...controlProps },
    fieldState: { error }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const [value, setValue] = useState(fieldValue ? toSuggestion(fieldValue) : null);

  const handleChange = useCallback((_: any, value: Suggestion) => {
    setValue(value);
  }, []);

  useUpdateEffect(() => {
    if (fieldValue !== toAddress(value)) {
      setValue(toSuggestion(fieldValue));
    }
  }, [fieldValue]);

  useUpdateEffect(() => {
    onChange(toAddress(value));
  }, [value, onChange]);

  const {
    value: searchValue,
    setValue: setSearchValue,
    suggestions: { data, loading }
  } = usePlaces();

  const defaultSuggestion = toDefaultSuggestion(searchValue);

  const options = useMemo(() => {
    const predefined = predefinedOptions.map(toSuggestion);

    return predefined.concat(data.length ? data : []).concat(searchValue.length > 10 ? [defaultSuggestion] : []);
  }, [data, searchValue, defaultSuggestion, predefinedOptions]);

  return (
    <FormField name={name} label={label} error={error?.message} description={description}>
      <Select
        filterOptions={(options) => options}
        value={value}
        getOptionSelected={(op, val) => op.description === val.description}
        getOptionLabel={(option) => option.description || ''}
        renderOption={(option) => (
          <Option>
            <strong>{option.structured_formatting.main_text}</strong>
            <small>{option.structured_formatting.secondary_text}</small>
          </Option>
        )}
        inputValue={searchValue}
        onInputChange={(_e, inputValue) => setSearchValue(inputValue)}
        loading={loading}
        options={options}
        {...controlProps}
        {...inputProps}
        data-test-id={`field-${controlProps.name}`}
        onChange={handleChange}
      />
    </FormField>
  );
};
