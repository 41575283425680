import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import { UpsertConnectTriggerDTO } from '@features/Platform/Integrations';
import { connectApi } from '@services/api/connectApi';
import { ReactQueryKey } from '@enums';

export const useUpsertConnectTrigger = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<void, Error, { integrationId: number; dto: UpsertConnectTriggerDTO }>(
    async ({ integrationId, dto }) => {
      try {
        await connectApi.upsertTrigger(integrationId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess(`Integration trigger created successfully`);
        queryClient.invalidateQueries([ReactQueryKey.ConnectIntegrationTrigger]);
      }
    }
  );
};
