import styled from 'styled-components';
import { colors } from '@styles';
import { CheckboxSize, CheckboxVariant } from './types';

export const Checkmark = styled.span`
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border-radius: 4px;
  border: 1px solid #c8c8d3;
  display: block;
  position: relative;
  flex-shrink: 0;
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const SIZE_MAP = {
  [CheckboxSize.Default]: '20px',
  [CheckboxSize.Small]: '16px'
};

const FONT_SIZE_MAP = {
  [CheckboxSize.Default]: '14px',
  [CheckboxSize.Small]: '12px'
};

export const Container = styled.label<{
  withLabel: boolean;
  isDisabled?: boolean;
  intermediate?: boolean;
  size: CheckboxSize;
  variant: CheckboxVariant;
}>`
  display: inline-flex;
  position: relative;
  gap: 8px;
  cursor: pointer;
  user-select: none;
  min-width: ${({ size }) => SIZE_MAP[size]};
  min-height: ${({ size }) => SIZE_MAP[size]};
  font-size: ${({ size }) => FONT_SIZE_MAP[size]};
  font-weight: 400;
  line-height: ${({ size }) => SIZE_MAP[size]};
  opacity: ${(props) => (props.isDisabled ? 0.4 : 1)};

  ${Checkmark} {
    border-radius: ${({ variant }) => (variant === CheckboxVariant.Circle ? '50%' : '4px')};
    width: ${({ size }) => SIZE_MAP[size]};
    height: ${({ size }) => SIZE_MAP[size]};
  }

  /* When the checkbox is checked, add a blue background */
  & ${Input}:checked ~ ${Checkmark} {
    background-color: ${colors.green};
    border-color: ${colors.green};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  & ${Checkmark}:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  & ${Input}:checked ~ ${Checkmark}:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  & ${Checkmark}:after {
    ${({ size, intermediate }) =>
      intermediate
        ? `
        top: 8px;
    height: 2px;
    width: 12px;
    left: 3px;
    border-radius: 4px;
    background-color: white;
    `
        : `
        left: 6px;
        top: 3px;
        width: 4px;
        height: 8px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);

        ${
          size === CheckboxSize.Small &&
          `
          left: 4px;
          top: 1px;
          width: 4px;
          height: 8px;
          border-width: 0 2px 2px 0;
        `
        }
    `}
  }
`;
