import { ConnectLogsConnection } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';

export const useConnectIntegrationLogs = (args: { integrationId: number; first: number; search: string }) =>
  useQuery<ConnectLogsConnection>(
    [ReactQueryKey.ConnectIntegrationLog, 'useConnectIntegrationLogs', args],
    async () => {
      try {
        return (
          await postGraphql<{ connectLogsConnection: ConnectLogsConnection }>(
            gql`
              query CONNECT_LOG_QUERY($integrationId: Int!, $first: Int!, $search: String) {
                connectLogsConnection(
                  condition: { integrationId: $integrationId }
                  first: $first
                  filter: { project: { title: { includesInsensitive: $search } } }
                  orderBy: ID_DESC
                ) {
                  nodes {
                    id
                    createdAt
                    status
                    raw
                    project {
                      id
                      title
                      type
                      uid
                      companyId
                    }
                  }
                  totalCount
                }
              }
            `,
            args
          )
        ).connectLogsConnection;
      } catch (e) {
        throw apiErrorHandler('Error fetching connect integration logs', e);
      }
    },
    {
      initialData: {
        nodes: [],
        totalCount: 0
      } as ConnectLogsConnection,
      keepPreviousData: true
    }
  );
