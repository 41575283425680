import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { ConnectIntegration } from '@generated/types/graphql';
import { errorHandler } from '@services/api/helpers';
import { CreateOpenSolarIntegrationDTO } from '@features/Platform/Integrations';
import { connectApi } from '@services/api/connectApi';
import { ReactQueryKey } from '@enums';

export const useCreateOpenSolarIntegration = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<ConnectIntegration, Error, CreateOpenSolarIntegrationDTO>(
    async (dto) => {
      try {
        return (await connectApi.createOpenSolarIntegration(dto)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess(`Integration with OpenSolar created successfully`);
        queryClient.invalidateQueries([ReactQueryKey.ConnectIntegration]);
      }
    }
  );
};
