import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronsUpIcon } from '@kit/ui/icons/ChevronsUp';
import { ChevronsDownIcon } from '@kit/ui/icons/ChevronsDown';
import { useAuth } from '@hooks/useAuth';
import { ContextMenu } from '@kit/components/ContextMenu';
import { EditIcon } from '@kit/ui/icons/Edit';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { convertTextToQuillFormat, getMentionAsMarkdown } from '@utils/quill';
import { useComments } from '@hooks/useComments';
import { CornerUpLeft, File as FileIcon, MessageSquare, Play } from 'react-feather';
import { colors } from '@styles';
import { useClickOutside } from '@react-hookz/web/cjs';
import { EntityType } from '@enums';
import { Link } from '@reach/router';
import { CommentType } from '@generated/types/graphql';
import { getFullName } from '@utils/utils';
import { QueryParamsEnum, useQueryParam, useQueryParamMutation } from '@hooks/useQueryParam';
import { RecordType } from '@types';
import { CommentForm } from '@features/ClientPortfolio/Client/CreateEventsPanel/forms/CommentForm';
import { UserAvatar } from '@kit/components/UserAvatar';
import { PinIcon } from '@kit/ui/icons/Pin';
import { UnpinIcon } from '@kit/ui/icons/Unpin';
import { PinFilledIcon } from '@kit/ui/icons/PinFilled';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { formatVideoDuration, isVideo } from '@utils/files';
import { hasEntityAccessNew } from '@utils';
import { FeedItem } from './types';
import {
  BorderContainer,
  ChildFeedItems,
  CommentContent,
  CommentFeedItem,
  CommentFilesGrid,
  CommentFileThumbnail,
  CommentFileThumbnailContainer,
  CommentHeader,
  CommentHeaderPart,
  CommentHeaderTitle,
  CommentWrapper,
  CreatedAt,
  ExpandRepliesButton,
  FeedItemIconContainer,
  FileNameAndThumbnail,
  InlineReplyForm,
  ItemActionButton,
  RemoveFileButton,
  Thumbnail,
  TruncatedComment,
  VideoBadge,
  NumberedAnnotation
} from './styled';
import { getHumanFriendlyTimeAgo } from './BaseItemTemplate';
import { ENTITY_NAME_BY_RECORD_TYPE } from './constants';

interface Props {
  item: FeedItem;
  parentItem?: FeedItem;
  initialIsRepliesExpanded?: boolean;
  isRepliesEnabled?: boolean;
  showAsPlainList?: boolean;
  context?: 'project' | 'workOrder' | 'file' | 'client' | 'system';
  contextEntityId?: number;
  onReplyClick?: (replyTo: FeedItem) => void;
  visibleRepliesCount?: number;
  commentNumberedAnnotation?: string;
}

const isTextClamped = (element: HTMLDivElement) => element.scrollHeight > element.clientHeight;

export const CommentItem = ({
  context,
  showAsPlainList = false,
  item,
  parentItem,
  initialIsRepliesExpanded = false,
  isRepliesEnabled = true,
  onReplyClick,
  contextEntityId,
  visibleRepliesCount = 1,
  commentNumberedAnnotation
}: Props) => {
  const isRootComment = !item.parentId && !showAsPlainList;
  const [isEdit, setIsEdit] = useState(false);

  const { isMe } = useAuth();
  const isCurrentUserEvent = isMe(item.createdBy);
  const canEdit = item.relatedComment && hasEntityAccessNew(item.relatedComment, 'create');
  const canDelete = item.relatedComment && hasEntityAccessNew(item.relatedComment, 'create');

  const [isMoreShown, setIsMoreShown] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const { openDrawer } = useDrawersContext();

  const [replyTo, setReplyTo] = useState<{ comment: string } | null>(null);

  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);
  const replies = !item.parentId && visibleRepliesCount > 0 ? item.childFeedsConnection.nodes : [];

  const isReplyHighlighted = feedId && replies.some((reply) => reply.id === +feedId);

  const [isRepliesExapanded, setIsRepliesExpanded] = useState(initialIsRepliesExpanded || isReplyHighlighted);

  const author = item.createdBy ?? { id: 0, firstName: '', lastName: '' };
  const { setParams } = useQueryParamMutation();

  const userName = [author.firstName, author.lastName].join(' ');

  const toggleExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const nodeRef = useRef();

  useEffect(() => {
    if (nodeRef.current) {
      setIsMoreShown(!showAsPlainList && isTextClamped(nodeRef.current));
    }
  }, [showAsPlainList]);

  const {
    update: { mutateAsync: updateComment },
    remove: { mutateAsync: removeComment },
    pin: { mutateAsync: updatePinComment }
  } = useComments();

  const confirmDelete = useConfirmDeleteModal();

  const handleUnpin = useCallback(async () => {
    await updatePinComment({
      id: item.relatedComment.id,
      dto: { isPinned: false }
    });
  }, [updatePinComment, item]);

  const removeAttachment = useCallback(
    async (fileId: number) => {
      await updateComment({
        id: item.relatedComment.id,
        dto: {
          comment: item.relatedComment.comment,
          fileIds: item.relatedComment.files.filter((file) => file.id !== fileId).map((file) => file.id)
        }
      });
    },
    [updateComment, item]
  );

  const menuItems = useMemo(() => {
    const pinComment = {
      icon: <PinIcon size="16px" color="#9C9CAA" />,
      title: 'Pin to the top',
      onClick: async () => {
        await updatePinComment({
          id: item.relatedComment.id,
          dto: { isPinned: true }
        });
      }
    };

    const unpinComment = {
      icon: <UnpinIcon size="16px" color="#9C9CAA" />,
      title: 'Unpin',
      onClick: handleUnpin
    };

    const pinMenuItem = item.relatedComment.isPinned ? unpinComment : pinComment;

    const noPinContext = context === 'dispatch';

    if (!isCurrentUserEvent) {
      return [!parentItem && !noPinContext && pinMenuItem].filter(Boolean);
    }

    return [
      canEdit && !parentItem && !noPinContext && pinMenuItem,
      canEdit && {
        icon: <EditIcon size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          setIsEdit(true);
        }
      },
      canDelete && {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this comment?')) {
            await removeComment({ id: item.relatedComment.id });

            setParams(
              {
                [QueryParamsEnum.FeedCursor]: undefined,
                [QueryParamsEnum.FeedId]: undefined
              },
              true
            );
          }
        }
      }
    ].filter(Boolean);
  }, [
    isCurrentUserEvent,
    item,
    confirmDelete,
    removeComment,
    parentItem,
    handleUnpin,
    context,
    updatePinComment,
    setParams,
    canEdit,
    canDelete
  ]);

  const handleChildReplyClick = useCallback((replyTo: FeedItem) => {
    setReplyTo({
      comment: `<p>${getMentionAsMarkdown(getFullName(replyTo.createdBy), replyTo.createdBy.id.toString())} </p>`
    });
  }, []);

  const replyFormRef = useRef(null);

  useEffect(() => {
    if (replyTo && replyFormRef.current) {
      replyFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [replyTo]);

  const handleReplyClick = useCallback(() => {
    if (parentItem) {
      onReplyClick?.(item);

      return;
    }

    setReplyTo({
      comment: `<p>${getMentionAsMarkdown(getFullName(item.createdBy), item.createdBy.id.toString())} </p>`
    });
  }, [parentItem, item, onReplyClick]);

  const formRef = useRef(null);
  useClickOutside(formRef, () => setIsEdit(false));
  useClickOutside(replyFormRef, () => setReplyTo(null));

  const ChildWrapperComponent = showAsPlainList ? React.Fragment : ChildFeedItems;

  const isSmallView = context !== 'project' && context !== 'client';

  const showProjectLink = context === 'client' && contextEntityId !== item.payload?.project?.id;

  const isCurrentOrParentPinned =
    (context !== 'dispatch' && item.relatedComment?.isPinned) || parentItem?.relatedComment?.isPinned;

  const annotation = commentNumberedAnnotation && context === 'file' ? commentNumberedAnnotation : null;

  return (
    <>
      <CommentFeedItem
        withGrayBg={isCurrentOrParentPinned}
        id={`feed_${item.id}`}
        isHighlighted={feedId && +feedId === item.id}
      >
        <BorderContainer>
          {isRootComment && (
            <FeedItemIconContainer>
              <MessageSquare color="#4D7CFE" size="16px" />
            </FeedItemIconContainer>
          )}
          <CommentContent>
            <CommentHeader>
              <CommentHeaderPart verticalAlign="flex-start">
                {author && <UserAvatar user={author} />}

                <CommentHeaderTitle>
                  <b>{userName}</b>
                  {isRootComment && ` commented`}
                  {isRootComment && context !== 'workOrder' && item.event === EntityType.TASK && (
                    <>
                      {' for '}
                      <Link to={`${window.location.pathname}/workOrders/${item.payload.id}${window.location.search}`}>
                        {item.payload.name}
                      </Link>
                    </>
                  )}
                  {isRootComment && context !== 'file' && item.event === EntityType.FILE && (
                    <>
                      {' for '}
                      <Link to={`${window.location.pathname}/docs/${item.payload.id}${window.location.search}`}>
                        <FileNameAndThumbnail>
                          {item.relatedComment?.file?.metaData?.thumbnailUrl && (
                            <Thumbnail src={item.relatedComment.file.metaData.thumbnailUrl} size={24} />
                          )}
                          {item.payload.name}
                        </FileNameAndThumbnail>
                      </Link>
                    </>
                  )}

                  {isRootComment && context !== 'system' && item.event === EntityType.SYSTEM && (
                    <>
                      {' for '}
                      <Link to={`${window.location.pathname}/systems/${item.payload.id}${window.location.search}`}>
                        <FileNameAndThumbnail>{item.payload.name}</FileNameAndThumbnail>
                      </Link>
                    </>
                  )}

                  {isRootComment && showProjectLink && (
                    <>
                      {` under ${ENTITY_NAME_BY_RECORD_TYPE[item.payload.project?.type ?? RecordType.PROJECT]} `}
                      <Link
                        to={`${window.location.pathname}/projects/${item.payload.project?.id}${window.location.search}`}
                      >
                        {item.payload.project?.title}
                      </Link>
                    </>
                  )}

                  {isSmallView && <CreatedAt noPaddingTop>{getHumanFriendlyTimeAgo(item.createdAt)}</CreatedAt>}
                </CommentHeaderTitle>
              </CommentHeaderPart>

              {!isEdit && context !== 'portfolio' && (
                <CommentHeaderPart verticalAlign="center">
                  {!isSmallView && <CreatedAt>{getHumanFriendlyTimeAgo(item.createdAt)}</CreatedAt>}
                  {isMoreShown && (
                    <ItemActionButton onClick={toggleExpand}>
                      {isExpanded ? <ChevronsUpIcon size="16px" /> : <ChevronsDownIcon size="16px" />}
                    </ItemActionButton>
                  )}
                  {annotation && <NumberedAnnotation>{annotation}</NumberedAnnotation>}
                  {isRepliesEnabled && (
                    <ItemActionButton onClick={handleReplyClick}>
                      <CornerUpLeft size="16px" color={colors.green} />
                    </ItemActionButton>
                  )}
                  {context !== 'dispatch' && item.relatedComment?.isPinned && canEdit && (
                    <ItemActionButton onClick={handleUnpin}>
                      <PinFilledIcon size="16px" color="#009688" />
                    </ItemActionButton>
                  )}
                  {menuItems.length > 0 && <ContextMenu items={menuItems} />}
                </CommentHeaderPart>
              )}
            </CommentHeader>

            {isEdit && (
              <CommentForm
                recordId={item.payload?.project?.id}
                initialValues={item.relatedComment}
                onClose={() => setIsEdit(false)}
                type={item.relatedComment.type}
                size="small"
                expandSize="small"
                ref={formRef}
              />
            )}

            {!isEdit && (
              <CommentWrapper withPadding={replies.length > 0 || showAsPlainList}>
                {isMoreShown && !isExpanded && (
                  <TruncatedComment ref={nodeRef}>
                    <div dangerouslySetInnerHTML={{ __html: convertTextToQuillFormat(item.relatedComment?.comment) }} />
                  </TruncatedComment>
                )}

                {(!isMoreShown || isExpanded) && (
                  <div dangerouslySetInnerHTML={{ __html: convertTextToQuillFormat(item.relatedComment?.comment) }} />
                )}

                {item.relatedComment?.files?.length > 0 && (
                  <CommentFilesGrid>
                    {item.relatedComment?.files.map((file) => (
                      <CommentFileThumbnailContainer
                        key={file.id}
                        onClick={() => {
                          openDrawer(
                            DrawerEntity.DOC,
                            file.id,
                            item.relatedComment?.files.map((a) => a.id)
                          );
                        }}
                      >
                        {file?.metaData?.thumbnailUrl ? (
                          <CommentFileThumbnail url={file.metaData.thumbnailUrl} />
                        ) : (
                          <FileIcon size="16px" color="#9C9CAA" />
                        )}

                        {isVideo(file) && (
                          <VideoBadge color="#1d1d35" bgColor="#fff">
                            <Play size="12px" color="#1d1d35" />
                            {formatVideoDuration(file.metaData?.duration ?? 0)}
                          </VideoBadge>
                        )}
                        {isCurrentUserEvent && canEdit && (
                          <RemoveFileButton
                            onClick={(e) => {
                              e.stopPropagation();
                              removeAttachment(file.id);
                            }}
                          >
                            <TrashIcon size="16px" />
                          </RemoveFileButton>
                        )}
                      </CommentFileThumbnailContainer>
                    ))}
                  </CommentFilesGrid>
                )}
              </CommentWrapper>
            )}
          </CommentContent>
        </BorderContainer>
      </CommentFeedItem>

      {replies.length > 0 && (
        <ChildWrapperComponent withGrayBg={isCurrentOrParentPinned}>
          {!isRepliesExapanded && replies.length > visibleRepliesCount && (
            <ExpandRepliesButton onClick={() => setIsRepliesExpanded(true)}>
              {replies.length - visibleRepliesCount} more{' '}
              {replies.length - visibleRepliesCount === 1 ? 'reply' : 'replies'}...
            </ExpandRepliesButton>
          )}
          {isRepliesExapanded &&
            replies.length > visibleRepliesCount &&
            replies
              .slice(0, visibleRepliesCount > 0 ? -visibleRepliesCount : replies.length)
              .map((child) => (
                <CommentItem
                  context={context}
                  showAsPlainList={showAsPlainList}
                  key={child.id}
                  item={child}
                  parentItem={item}
                  isRepliesEnabled={isRepliesEnabled}
                  onReplyClick={handleChildReplyClick}
                />
              ))}

          {visibleRepliesCount > 0 &&
            replies
              .slice(-visibleRepliesCount)
              .map((child) => (
                <CommentItem
                  key={child.id}
                  context={context}
                  showAsPlainList={showAsPlainList}
                  item={child}
                  parentItem={item}
                  isRepliesEnabled={isRepliesEnabled}
                  onReplyClick={handleChildReplyClick}
                />
              ))}
        </ChildWrapperComponent>
      )}

      {replyTo && (
        <InlineReplyForm withGrayBg={isCurrentOrParentPinned}>
          <CommentForm
            recordId={item.payload?.project?.id}
            workOrderId={item.relatedComment.type === CommentType.Task ? item.payload.id : undefined}
            fileId={item.relatedComment.type === CommentType.File ? item.payload.id : undefined}
            systemId={item.relatedComment.type === CommentType.System ? item.payload.id : undefined}
            parentId={item.relatedComment.id}
            type={item.relatedComment.type}
            onClose={() => setReplyTo(null)}
            initialValues={replyTo}
            size="small"
            parentItem={item}
            ref={replyFormRef}
            isExpandable
            expandSize={context === 'project' || context === 'client' ? 'default' : 'small'}
          >
            <CommentItem showAsPlainList item={item} initialIsRepliesExpanded isRepliesEnabled={false} />
          </CommentForm>
        </InlineReplyForm>
      )}
    </>
  );
};
