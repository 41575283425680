import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { useCreateOpenSolarIntegration } from '@hooks/workspace/connectIntegration';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useCallback } from 'react';
import { Info } from 'react-feather';
import { colors } from '@styles';
import { Box } from '@material-ui/core';
import { CredsAlert, CredsHeader, CredsSubtitle, CredsTitle } from './styled';

interface FormValues {
  username: string;
  password: string;
  mfaToken?: string;
}

interface Props {
  onClose: (newId: number | void) => void;
}

const RULES: FormValidationRules<FormValues> = {
  username: {
    isRequired: true
  },
  password: {
    isRequired: true
  },
  mfaToken: {
    isRequired: false
  }
};

export const CreateOpenSolarIntegration = ({ onClose }: Props) => {
  const { mutateAsync: create } = useCreateOpenSolarIntegration();

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: async (formValues) => {
      const result = await create(formValues);

      onClose(result.id);
    }
  });

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    control,
    formState: { isSubmitting }
  } = form;

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <ModalBody>
        <Box display="flex" flexDirection="column" gridGap={32}>
          <CredsAlert>
            <CredsHeader>
              <Info size={24} color={colors.blue} />
              <CredsTitle>We do not get access to your OpenSolar account.</CredsTitle>
            </CredsHeader>
            <CredsSubtitle>
              We do not store your OpenSolar account credentials. We only send it to OpenSolar to get app access tokens.
            </CredsSubtitle>
          </CredsAlert>

          <div>
            <InputField control={control} name="username" label="Username" autoComplete="username" />
            <InputField
              control={control}
              name="password"
              label="Password"
              type="password"
              autoComplete="current-password"
            />
            <InputField
              control={control}
              name="mfaToken"
              label="2FA token"
              autoComplete="one-time-code"
              placeholder="Leave empty if you do not use 2 Factor Auth for your OpenSolar account"
            />
          </div>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancel} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Create
        </Button>
      </ModalFooter>
    </Form>
  );
};
