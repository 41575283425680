import { DateTime } from 'luxon';

import { AnalyticsWidgetDateRangeType } from './types';

export type DateRangeOption = {
  id: AnalyticsWidgetDateRangeType;
  name: string;
  startDate?: () => DateTime;
  endDate?: () => DateTime;
};

export const dateRangeConfig: Record<AnalyticsWidgetDateRangeType, DateRangeOption> = {
  [AnalyticsWidgetDateRangeType.TODAY]: {
    id: AnalyticsWidgetDateRangeType.TODAY,
    name: 'Today',
    startDate: () => DateTime.local().startOf('day'),
    endDate: () => DateTime.local().endOf('day')
  },
  [AnalyticsWidgetDateRangeType.THIS_WEEK_TO_DATE]: {
    id: AnalyticsWidgetDateRangeType.THIS_WEEK_TO_DATE,
    name: 'Week-to-date',
    startDate: () => DateTime.local().startOf('week'),
    endDate: () => DateTime.local().endOf('day')
  },
  [AnalyticsWidgetDateRangeType.THIS_MONTH_TO_DATE]: {
    id: AnalyticsWidgetDateRangeType.THIS_MONTH_TO_DATE,
    name: 'Month-to-date',
    startDate: () => DateTime.local().startOf('month'),
    endDate: () => DateTime.local().endOf('day')
  },
  [AnalyticsWidgetDateRangeType.THIS_QUARTER_TO_DATE]: {
    id: AnalyticsWidgetDateRangeType.THIS_QUARTER_TO_DATE,
    name: 'Quarter-to-date',
    startDate: () => DateTime.local().startOf('quarter'),
    endDate: () => DateTime.local().endOf('day')
  },
  [AnalyticsWidgetDateRangeType.THIS_YEAR_TO_DATE]: {
    id: AnalyticsWidgetDateRangeType.THIS_YEAR_TO_DATE,
    name: 'Year-to-date',
    startDate: () => DateTime.local().startOf('year'),
    endDate: () => DateTime.local().endOf('day')
  },
  [AnalyticsWidgetDateRangeType.THIS_WEEK]: {
    id: AnalyticsWidgetDateRangeType.THIS_WEEK,
    name: 'This Week',
    startDate: () => DateTime.local().startOf('week'),
    endDate: () => DateTime.local().endOf('week')
  },
  [AnalyticsWidgetDateRangeType.THIS_MONTH]: {
    id: AnalyticsWidgetDateRangeType.THIS_MONTH,
    name: 'This Month',
    startDate: () => DateTime.local().startOf('month'),
    endDate: () => DateTime.local().endOf('month')
  },
  [AnalyticsWidgetDateRangeType.THIS_QUARTER]: {
    id: AnalyticsWidgetDateRangeType.THIS_QUARTER,
    name: 'This Quarter',
    startDate: () => DateTime.local().startOf('quarter'),
    endDate: () => DateTime.local().endOf('quarter')
  },
  [AnalyticsWidgetDateRangeType.THIS_YEAR]: {
    id: AnalyticsWidgetDateRangeType.THIS_YEAR,
    name: 'This Year',
    startDate: () => DateTime.local().startOf('year'),
    endDate: () => DateTime.local().endOf('year')
  },
  [AnalyticsWidgetDateRangeType.YESTERDAY]: {
    id: AnalyticsWidgetDateRangeType.YESTERDAY,
    name: 'Yesterday',
    startDate: () => DateTime.local().startOf('day').minus({ day: 1 }),
    endDate: () => DateTime.local().endOf('day').minus({ day: 1 })
  },
  [AnalyticsWidgetDateRangeType.LAST_WEEK]: {
    id: AnalyticsWidgetDateRangeType.LAST_WEEK,
    name: 'Last Week',
    startDate: () => DateTime.local().startOf('week').minus({ week: 1 }),
    endDate: () => DateTime.local().endOf('week').minus({ week: 1 })
  },
  [AnalyticsWidgetDateRangeType.LAST_MONTH]: {
    id: AnalyticsWidgetDateRangeType.LAST_MONTH,
    name: 'Last Month',
    startDate: () => DateTime.local().startOf('month').minus({ month: 1 }),
    endDate: () => DateTime.local().minus({ month: 1 }).endOf('month')
  },
  [AnalyticsWidgetDateRangeType.LAST_QUARTER]: {
    id: AnalyticsWidgetDateRangeType.LAST_QUARTER,
    name: 'Last Quarter',
    startDate: () => DateTime.local().startOf('quarter').minus({ quarter: 1 }),
    endDate: () => DateTime.local().endOf('quarter').minus({ quarter: 1 })
  },
  [AnalyticsWidgetDateRangeType.LAST_YEAR]: {
    id: AnalyticsWidgetDateRangeType.LAST_YEAR,
    name: 'Last Year',
    startDate: () => DateTime.local().startOf('year').minus({ year: 1 }),
    endDate: () => DateTime.local().endOf('year').minus({ year: 1 })
  },
  [AnalyticsWidgetDateRangeType.LAST_7_DAYS]: {
    id: AnalyticsWidgetDateRangeType.LAST_7_DAYS,
    name: 'Last 7 Days',
    startDate: () => DateTime.local().startOf('day').minus({ day: 7 }),
    endDate: () => DateTime.local().endOf('day').minus({ day: 1 })
  },
  [AnalyticsWidgetDateRangeType.LAST_30_DAYS]: {
    id: AnalyticsWidgetDateRangeType.LAST_30_DAYS,
    name: 'Last 30 Days',
    startDate: () => DateTime.local().startOf('day').minus({ day: 30 }),
    endDate: () => DateTime.local().endOf('day').minus({ day: 1 })
  },
  [AnalyticsWidgetDateRangeType.LAST_60_DAYS]: {
    id: AnalyticsWidgetDateRangeType.LAST_60_DAYS,
    name: 'Last 60 Days',
    startDate: () => DateTime.local().startOf('day').minus({ day: 60 }),
    endDate: () => DateTime.local().endOf('day').minus({ day: 1 })
  },
  [AnalyticsWidgetDateRangeType.LAST_90_DAYS]: {
    id: AnalyticsWidgetDateRangeType.LAST_90_DAYS,
    name: 'Last 90 Days',
    startDate: () => DateTime.local().startOf('day').minus({ day: 90 }),
    endDate: () => DateTime.local().endOf('day').minus({ day: 1 })
  },
  [AnalyticsWidgetDateRangeType.LAST_180_DAYS]: {
    id: AnalyticsWidgetDateRangeType.LAST_180_DAYS,
    name: 'Last 180 Days',
    startDate: () => DateTime.local().startOf('day').minus({ day: 180 }),
    endDate: () => DateTime.local().endOf('day').minus({ day: 1 })
  },
  [AnalyticsWidgetDateRangeType.LAST_365_DAYS]: {
    id: AnalyticsWidgetDateRangeType.LAST_365_DAYS,
    name: 'Last 365 Days',
    startDate: () => DateTime.local().startOf('day').minus({ day: 365 }),
    endDate: () => DateTime.local().endOf('day').minus({ day: 1 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_WEEK]: {
    id: AnalyticsWidgetDateRangeType.NEXT_WEEK,
    name: 'Next Week',
    startDate: () => DateTime.local().startOf('week').plus({ week: 1 }),
    endDate: () => DateTime.local().endOf('week').plus({ week: 1 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_MONTH]: {
    id: AnalyticsWidgetDateRangeType.NEXT_MONTH,
    name: 'Next Month',
    startDate: () => DateTime.local().startOf('month').plus({ month: 1 }),
    endDate: () => DateTime.local().endOf('month').plus({ month: 1 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_QUARTER]: {
    id: AnalyticsWidgetDateRangeType.NEXT_QUARTER,
    name: 'Next Quarter',
    startDate: () => DateTime.local().startOf('quarter').plus({ quarter: 1 }),
    endDate: () => DateTime.local().endOf('quarter').plus({ quarter: 1 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_YEAR]: {
    id: AnalyticsWidgetDateRangeType.NEXT_YEAR,
    name: 'Next Year',
    startDate: () => DateTime.local().startOf('year').plus({ year: 1 }),
    endDate: () => DateTime.local().endOf('year').plus({ year: 1 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_7_DAYS]: {
    id: AnalyticsWidgetDateRangeType.NEXT_7_DAYS,
    name: 'Next 7 Days',
    startDate: () => DateTime.local().startOf('day').plus({ day: 1 }),
    endDate: () => DateTime.local().endOf('day').plus({ day: 7 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_30_DAYS]: {
    id: AnalyticsWidgetDateRangeType.NEXT_30_DAYS,
    name: 'Next 30 Days',
    startDate: () => DateTime.local().startOf('day').plus({ day: 1 }),
    endDate: () => DateTime.local().endOf('day').plus({ day: 30 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_60_DAYS]: {
    id: AnalyticsWidgetDateRangeType.NEXT_60_DAYS,
    name: 'Next 60 Days',
    startDate: () => DateTime.local().startOf('day').plus({ day: 1 }),
    endDate: () => DateTime.local().endOf('day').plus({ day: 60 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_90_DAYS]: {
    id: AnalyticsWidgetDateRangeType.NEXT_90_DAYS,
    name: 'Next 90 Days',
    startDate: () => DateTime.local().startOf('day').plus({ day: 1 }),
    endDate: () => DateTime.local().endOf('day').plus({ day: 90 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_180_DAYS]: {
    id: AnalyticsWidgetDateRangeType.NEXT_180_DAYS,
    name: 'Next 180 Days',
    startDate: () => DateTime.local().startOf('day').plus({ day: 1 }),
    endDate: () => DateTime.local().endOf('day').plus({ day: 180 })
  },
  [AnalyticsWidgetDateRangeType.NEXT_365_DAYS]: {
    id: AnalyticsWidgetDateRangeType.NEXT_365_DAYS,
    name: 'Next 365 Days',
    startDate: () => DateTime.local().startOf('day').plus({ day: 1 }),
    endDate: () => DateTime.local().endOf('day').plus({ day: 365 })
  },
  [AnalyticsWidgetDateRangeType.ALL_TIME]: {
    id: AnalyticsWidgetDateRangeType.ALL_TIME,
    name: 'All time'
  },
  [AnalyticsWidgetDateRangeType.CUSTOM]: {
    id: AnalyticsWidgetDateRangeType.CUSTOM,
    name: 'Custom'
  },
  [AnalyticsWidgetDateRangeType.CUSTOM_TO_DATE]: {
    id: AnalyticsWidgetDateRangeType.CUSTOM_TO_DATE,
    name: 'Custom-to-date',
    endDate: () => DateTime.local().endOf('day')
  }
};

export const DEFAULT_DATE_RANGE_OPTION = dateRangeConfig[AnalyticsWidgetDateRangeType.LAST_30_DAYS];
