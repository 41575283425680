import React, { useCallback, useState } from 'react';
import { Folder, Info } from 'react-feather';
import moment from 'moment';
import { Collapse } from '@material-ui/core';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { File } from '@generated/types/graphql';
import { isImageFile, isPdfFile } from '@utils/files';
import { parseUtcDate } from '@utils/dates';
import { DataBlock, DataGrid, DataLabel, FileSource, FSContainer, FSTitle, LocationValue } from './styled';
import { ConnectIntegrationType } from '@features/Platform/Integrations';
import { ConnectIntegrationBrand } from '@features/Platform/Integrations/Common/ConnectIntegrationBrand/ConnectIntegrationBrand';

const getFileType = (file: File) => {
  if (file.form) {
    return 'Form';
  }

  if (isImageFile(file)) {
    return 'Image';
  }

  if (isPdfFile(file)) {
    return 'PDF';
  }

  return 'File';
};

interface Props {
  file?: File;
}

export const FSInfo = ({ file }: Props) => {
  const isForm = file?.type === 'form';

  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  if (!file) {
    return null;
  }

  return (
    <FSContainer className="info">
      <FSTitle onClick={toggleCollapse}>
        <Info size="16px" />
        Info
        {isCollapsed ? <ChevronDownIcon size="16px" /> : <ChevronUpIcon size="16px" />}
      </FSTitle>

      <Collapse in={!isCollapsed}>
        <DataGrid>
          <DataBlock>
            <DataLabel>Location</DataLabel>
            <LocationValue>
              <Folder size="12px" color="#98A9BC" /> Documents
            </LocationValue>
          </DataBlock>

          {!isForm && (
            <DataBlock>
              {' '}
              <DataLabel>Size</DataLabel>
              <div>{`${(file.metaData.size / 1000 / 1000).toFixed(2)} MB`}</div>
            </DataBlock>
          )}
          <DataBlock>
            <DataLabel>Type</DataLabel>
            <div>{getFileType(file)}</div>
          </DataBlock>
          <DataBlock>
            {' '}
            <DataLabel>Created</DataLabel>
            <div>{moment(parseUtcDate(file.createdAt)).format('MM/DD/YY hh:mm a')}</div>
          </DataBlock>

          {file.sourceLog && (
            <DataBlock>
              <DataLabel>Source</DataLabel>
              <FileSource>
                <ConnectIntegrationBrand type={file.sourceLog.integration.type as ConnectIntegrationType} />
              </FileSource>
            </DataBlock>
          )}

          {isForm && (
            <DataBlock>
              <DataLabel>Updated</DataLabel>
              <div>{moment(parseUtcDate(file.updatedAt)).format('MM/DD/YY hh:mm a')}</div>
            </DataBlock>
          )}
        </DataGrid>
      </Collapse>
    </FSContainer>
  );
};
