import styled, { css } from 'styled-components';
import { colors } from '@styles';

export const Container = styled.div`
  max-width: 775px;
`;

export const ProposalToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;

export const ProposalTool = styled.div<{ installed: boolean }>`
  padding: 24px;
  background-color: ${colors.white};
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.installed &&
    css`
      cursor: pointer;
    `}
`;

export const ProposalToolDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  width: 320px;
  color: #828d9a;
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

export const Header = styled.div`
  padding: 24px 0;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const H2 = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
`;
