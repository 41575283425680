import { WidgetSettings } from '@features/Analytics/types';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { TaskReportsConnection } from '@generated/types/graphql';
import { WorkOrdersWidgetDataParams } from '../types';
import { BarData } from '../../Widget/types';
import { postProcessingData } from './postProcessingData';
import { buildAggregationResponseFields, buildRequestParams, extractGroupAggregationValue } from './helpers';
import { RequestCacheType } from '../../../../enums';

const requestGroupedData = (companyId: number, settings: WidgetSettings) => {
  const { filter, condition, groupBy } = buildRequestParams({ companyId, settings });

  const aggregationResponseFields = buildAggregationResponseFields(settings);

  return postGraphql<{ taskReportsConnection: TaskReportsConnection }>(
    gql`
        query WORK_ORDERS_BAR_LIKE_DATA_QUERY($filter: TaskReportFilter!, $condition: TaskReportCondition, $groupBy: [TaskReportGroupBy!]!) {
            taskReportsConnection(filter: $filter, condition: $condition) {
                groupedAggregates(groupBy: $groupBy) {
                    keys
                    ${aggregationResponseFields}
                }   
            }
        }
    `,
    {
      filter,
      condition,
      groupBy
    },
    undefined,
    { useReplica: true, cache: RequestCacheType.Long }
  );
};

export const fetchWorkOrdersBarLikeData = async ({
  companyId,
  settings,
  usersMap,
  userToTeamsMap,
  workOrderStatusesMap,
  workOrderTemplatesMap,
  timezone
}: WorkOrdersWidgetDataParams): Promise<BarData> => {
  const result = await requestGroupedData(companyId, settings);

  return postProcessingData({
    companyId,
    data: {
      points: result.taskReportsConnection.groupedAggregates.map((group) => ({
        x: (group.keys[0] as string) ?? 'Empty',
        originalX: group.keys[0] as string | number | number[] | null,
        y: Math.round(parseFloat(extractGroupAggregationValue(settings, group)) * 100) / 100,
        isEmpty: group.keys[0] === null
      }))
    },
    settings,
    usersMap,
    userToTeamsMap,
    workOrderStatusesMap,
    workOrderTemplatesMap,
    timezone
  });
};
