export const elementOrParentsHasClass = (element: HTMLElement, className: string): boolean => {
  if (!element) {
    return false;
  }

  if (!(element instanceof HTMLElement) || !('classList' in element)) {
    return false;
  }

  return element.classList.contains(className) || elementOrParentsHasClass(element.parentElement, className);
};
