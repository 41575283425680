import React, { useEffect, useMemo, useRef, useState } from 'react';

import { StateMessege } from '@common';
import { useActivity } from '@hooks/useActivity';
import { flatPagesData } from '@utils/reactQuery';
import { useIntersectionObserver } from '@react-hookz/web/esm/useIntersectionObserver';
import { Tabs } from '@kit/ui/Tabs';
import { EntityType, ReactQueryKey, TaskEventType } from '@enums';
import { CommentType, PrivilegedTask } from '@generated/types/graphql';
import { FeedItem } from '@features/ProjectPortfolio/Project/History/FeedItem';
import { useUnmountEffect, useUpdateEffect } from '@react-hookz/web';
import { QueryParamsEnum, useQueryParam, useQueryParamMutation } from '@hooks/useQueryParam';
import { useQueryClient } from 'react-query';
import { CommentForm } from '@features/ClientPortfolio/Client/CreateEventsPanel/forms/CommentForm';
import { PinnedComments } from '@features/ProjectPortfolio/Project/History/PinnedComments';
import { SubjectAccess } from '@common/RoleAccess';
import { Container, Feed, FormContainer, Header, HistoryContainer } from './styled';
import { useSubscribeToUpdates } from './useSubscribeToUpdates';

const TABS = [
  {
    id: 'all',
    title: 'All'
  },
  {
    id: 'comment',
    title: 'Comments',
    types: [
      {
        entity: EntityType.TASK,
        event: TaskEventType.COMMENTED
      }
    ]
  },
  {
    id: 'log',
    title: 'Log',
    notTypes: [
      {
        entity: EntityType.TASK,
        event: TaskEventType.COMMENTED
      }
    ]
  }
];

/*
TODO refactor this component
it is mostly copy-pasted from src/components/Project/Activity
*/
export const Activity = ({ workOrder }: { workOrder: PrivilegedTask }) => {
  const [tab, setTab] = useState(TABS[0]);

  const loadingNodeRef = useRef<HTMLDivElement>();
  const loadingTopNodeRef = useRef<HTMLDivElement>();

  const loadingRef = useRef<boolean>(false);
  const loadingPrevRef = useRef<boolean>(false);

  const isLoadPreviousEnabled = useRef<boolean>(true);
  const isScrolledToFeedItem = useRef<boolean>(false);

  const [feedCursor] = useQueryParam(QueryParamsEnum.FeedCursor);
  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);

  const entry = useIntersectionObserver(loadingNodeRef.current);
  const topEntry = useIntersectionObserver(loadingTopNodeRef.current);

  const { setParams } = useQueryParamMutation();

  useUpdateEffect(() => {
    setParams(
      {
        [QueryParamsEnum.FeedCursor]: undefined,
        [QueryParamsEnum.FeedId]: undefined
      },
      true
    );
  }, [tab, workOrder.id]);

  const queryClient = useQueryClient();

  const { data, isLoading, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage } = useActivity({
    queryKey: ReactQueryKey.WorkOrderActivity,
    types: tab.types,
    taskId: workOrder.id,
    notTypes: tab.notTypes,
    equalOrBeforeDate: feedCursor ? decodeURIComponent(feedCursor) : undefined
  });

  useUpdateEffect(() => {
    if (feedCursor) {
      isScrolledToFeedItem.current = false;
      queryClient.removeQueries([ReactQueryKey.ProjectActivity]);
    }
  }, [feedCursor, queryClient]);

  useUnmountEffect(() => {
    if (feedCursor) {
      queryClient.removeQueries([ReactQueryKey.ProjectActivity]);

      setParams(
        {
          [QueryParamsEnum.FeedCursor]: undefined,
          [QueryParamsEnum.FeedId]: undefined
        },
        true
      );
    }
  });

  useSubscribeToUpdates(workOrder.id);

  const flatFeed = useMemo(() => {
    return flatPagesData(data);
  }, [data]);

  useEffect(() => {
    if (entry?.isIntersecting && hasNextPage) {
      if (!loadingRef.current) {
        loadingRef.current = true;
        fetchNextPage().finally(() => {
          loadingRef.current = false;
        });
      }
    }
  }, [entry, hasNextPage, data, fetchNextPage]);

  useEffect(() => {
    if (feedId && feedCursor && topEntry?.isIntersecting && hasPreviousPage && isLoadPreviousEnabled.current) {
      if (!loadingPrevRef.current) {
        loadingPrevRef.current = true;
        fetchPreviousPage().finally(() => {
          loadingPrevRef.current = false;

          const node = document.getElementById(`feed_${feedId}`);

          if (node && !isScrolledToFeedItem.current) {
            isLoadPreviousEnabled.current = false;
            node.scrollIntoView();
            isScrolledToFeedItem.current = true;

            setTimeout(() => {
              isLoadPreviousEnabled.current = true;
            }, 1000);
          }
        });
      }
    }
  }, [feedId, feedCursor, topEntry, flatFeed, data, fetchPreviousPage, hasPreviousPage]);

  return (
    <HistoryContainer>
      <Header>
        <Tabs tabs={TABS} selected={tab.id} onChange={setTab} />

        <SubjectAccess subject="comment" privilege="create">
          <FormContainer>
            <CommentForm
              expandSize="small"
              size="small"
              type={CommentType.Task}
              workOrderId={workOrder.id}
              recordId={workOrder.project.id}
            />
          </FormContainer>
        </SubjectAccess>
      </Header>
      <Container>
        <Feed>
          {(tab.id === 'all' || tab.id === 'comment') && <PinnedComments workOrderId={workOrder.id} />}
          {flatFeed.length === 0 && !isLoading ? (
            <StateMessege subTitle="Looks like there's no activity here yet." />
          ) : (
            <>
              <p ref={loadingTopNodeRef} />
              {flatFeed.map((item) => (
                <FeedItem contextEntityId={workOrder.id} key={item.id} item={item} context="workOrder" />
              ))}

              <p ref={loadingNodeRef} />
            </>
          )}
        </Feed>
      </Container>
    </HistoryContainer>
  );
};
