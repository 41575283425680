import { ConnectTrigger, ConnectTriggerType } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';

export const useConnectPostSaleTrigger = (args: { integrationId: number; type: ConnectTriggerType }) =>
  useQuery<ConnectTrigger>(
    [ReactQueryKey.ConnectIntegrationTrigger, 'useConnectPostSaleTrigger', args],
    async () => {
      try {
        return (
          await postGraphql<{ connectTriggerByTypeAndIntegrationId: ConnectTrigger }>(
            gql`
              query POST_SALE_TRIGGER_QUERY($type: ConnectTriggerType!, $integrationId: Int!) {
                connectTriggerByTypeAndIntegrationId(type: $type, integrationId: $integrationId) {
                  id
                  type
                  integrationId
                  config
                }
              }
            `,
            args
          )
        ).connectTriggerByTypeAndIntegrationId;
      } catch (e) {
        throw apiErrorHandler('Error fetching trigger', e);
      }
    },
    {
      initialData: null
    }
  );
