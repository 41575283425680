import { ConnectTriggerType } from '@generated/types/graphql';
import { ImportMatchFoundStrategy, Property } from '@types';

export type CreateAuroraIntegrationDTO = {
  token: string;
  tenant: string;
};

export type CreateOpenSolarIntegrationDTO = {
  username: string;
  password: string;
  mfaToken?: string;
};

export type UpdateConnectIntegrationDTO = {
  matchFoundStrategy?: ImportMatchFoundStrategy;
  blueprintId?: number | null;
  matchFallbackProjectColumnId?: number;
};

export type ConnectColumnMappingDTO = {
  columnId: number;
  valuePath: string;
};

export enum ConnectIntegrationType {
  AURORA_SOLAR = 'AURORA_SOLAR',
  OPEN_SOLAR = 'OPEN_SOLAR'
}

export type MappingOption = {
  column: Omit<Property, 'id'>;
  /**
   * Full path to the field in the integration data blob, like `project.name` or `consumptionProfile.efficiency_packages[0].efficiency_package_name`
   */
  path: string;
};

export enum TriggerEventName {
  AgreementStatusChanged = 'agreementStatusChanged',
  ProjectStatusChanged = 'projectStatusChanged'
}

export type UpsertConnectTriggerDTO = {
  type: ConnectTriggerType;
  eventName: TriggerEventName;
  eventValue: string;
};

interface ConnectTriggerEventOption {
  value: string;
  name: string;
  default?: boolean;
}

export interface ConnectTriggerEvent {
  value: TriggerEventName;
  name: string;
  options?: ConnectTriggerEventOption[];
}

export type ConnectTriggerOptions = {
  [key in ConnectTriggerType]?: ConnectTriggerEvent[];
};

export type AuroraTriggerConfig = {
  event: TriggerEventName;
  value: string;
};

export type OpenSolarTriggerConfig = {
  event: TriggerEventName;
  value: string;
};

export type ConnectTriggerConfig = AuroraTriggerConfig | OpenSolarTriggerConfig;
