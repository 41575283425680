import React, { useCallback } from 'react';

import { Container, Input, Checkmark } from './styled';
import { CheckboxVariant, CheckboxSize } from './types';

interface Props {
  isChecked: boolean;
  onChange?: (isChecked: boolean) => void;
  onClick?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  onMouseEnter?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLLabelElement>) => void;
  label?: string;
  className?: string;
  isDisabled?: boolean;
  intermediate?: boolean;
  variant?: CheckboxVariant;
  size?: CheckboxSize;
  tooltip?: string;
}

export const Checkbox = ({
  isChecked,
  intermediate,
  onChange,
  onClick,
  label,
  className,
  isDisabled = false,
  onMouseEnter,
  onMouseLeave,
  variant = CheckboxVariant.Default,
  size = CheckboxSize.Default,
  tooltip
}: Props) => {
  const handleLabelClick = useCallback(
    (e: React.MouseEvent<HTMLLabelElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (onChange) {
        onChange(!isChecked);
      } else {
        onClick?.(e);
      }
    },
    [onClick, onChange, isChecked]
  );

  return (
    <Container
      isDisabled={isDisabled}
      onClick={isDisabled ? undefined : handleLabelClick}
      className={className}
      withLabel={Boolean(label)}
      intermediate={intermediate}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      size={size}
      variant={variant}
      data-tooltip-content={tooltip}
      data-tooltip-id="react-root-tooltip"
      data-tooltip-hidden={!tooltip}
    >
      <Input type="checkbox" disabled={isDisabled} checked={intermediate || isChecked} />
      <Checkmark />
      {label}
    </Container>
  );
};
