import { KpiData } from '@features/Analytics/Widget/types';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { TaskReportsConnection } from '@generated/types/graphql';
import { RequestCacheType } from '@enums';

import { buildAggregationResponseFields, buildRequestParams, extractGroupAggregationValue } from './helpers';
import { WorkOrdersWidgetDataParams } from '../types';
import { BuildRequestArgs } from './types';
import { calcDiffInDaysForDateRangeOption } from '../helpers';

const requestAggregatedData = ({ companyId, settings, calculatePreviousPeriod, teamsMap }: BuildRequestArgs) => {
  const { filter, condition } = buildRequestParams({ companyId, settings, calculatePreviousPeriod, teamsMap });

  const aggregationResponseFields = buildAggregationResponseFields(settings);

  return postGraphql<{ taskReportsConnection: TaskReportsConnection }>(
    gql`
          query WORK_ORDERS_KPI_DATA_QUERY($filter: TaskReportFilter!, $condition: TaskReportCondition) {
              taskReportsConnection(filter: $filter, condition: $condition) {
                  aggregates {
                      ${aggregationResponseFields}
                  }
              }
          }
      `,
    {
      filter,
      condition
    },
    undefined,
    { useReplica: true, cache: RequestCacheType.Long }
  );
};

export const fetchWorkOrdersKpiData = async ({
  companyId,
  settings,
  teamsMap
}: WorkOrdersWidgetDataParams): Promise<KpiData> => {
  const result = await requestAggregatedData({ companyId, settings, teamsMap });
  const prevPeriodResult = await requestAggregatedData({
    companyId,
    settings,
    calculatePreviousPeriod: true,
    teamsMap
  });

  const value = extractGroupAggregationValue(settings, result.taskReportsConnection.aggregates) as string | null;
  const prevPeriodValue = extractGroupAggregationValue(settings, prevPeriodResult.taskReportsConnection.aggregates) as
    | string
    | null;

  const daysInPeriod = Math.round(
    calcDiffInDaysForDateRangeOption(settings.dateRangeType, settings.dateRangeStart, settings.dateRangeEnd)
  );

  const valueAsNumber = value !== null ? parseFloat(value) : null;
  const prevValueAsNumber = prevPeriodValue !== null ? parseFloat(prevPeriodValue) : null;

  let diffInPercent;

  if (valueAsNumber === null || prevValueAsNumber === null) {
    diffInPercent = null;
  } else if (prevValueAsNumber === 0) {
    diffInPercent = null;
  } else {
    diffInPercent = ((valueAsNumber - prevValueAsNumber) / prevValueAsNumber) * 100;
  }

  return {
    isEmpty: value === null,
    value: valueAsNumber,
    daysInPeriod,
    diffInPercent
  };
};
