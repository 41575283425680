import moment, { Moment, unitOfTime } from 'moment';

import { SeparatedList } from '@types';
import { DateTime, Duration } from 'luxon';

export const isValidDate = (value: Date | string | Moment) => {
  if (!value) {
    return false;
  }

  return moment(value).isValid();
};

export const parseUtcDate = (dateTimeStr: string | Date) => {
  return moment.utc(dateTimeStr).local().toDate();
};

export const formatDate = (value: moment.MomentInput, format = 'hh:mm A') => {
  if (!value) {
    return value as string;
  }

  const momentDate = moment.utc(value).local();

  return momentDate.isValid() ? momentDate.format(format) : '';
};

export const getHumanFriendlyTimeAgo = (date: moment.MomentInput) => {
  return moment.utc(date).local().calendar(null, {
    sameDay: '[Today at] hh:mm a',
    lastDay: '[Yesterday at] hh:mm a',
    lastWeek: 'dddd [at] hh:mm a',
    sameElse: 'MMM Do [at] hh:mm a'
  });
};

export const separateByDate = <T>(list: T[], dateFinder: (i: T) => string) =>
  list.reduce((acc, item) => {
    const separator = moment.utc(dateFinder(item)).local().calendar(null, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'dddd',
      sameElse: 'MMM Do'
    });

    const separatorIndex = acc.findIndex((s) => s.separator === separator);
    if (separatorIndex > -1) {
      acc[separatorIndex].list.push(item);

      return acc;
    }

    acc.push({
      separator,
      list: [item]
    });

    return acc;
  }, [] as SeparatedList<T>[]);

export const addHours = (date: Date, hours: number) => {
  const result = new Date(date);

  result.setHours(result.getHours() + hours);

  return result;
};

export const getTimeDiff = (
  endDate: moment.MomentInput,
  startDate: moment.MomentInput,
  diffIn: unitOfTime.Diff = 'd'
) => {
  const admission = moment(startDate);
  const discharge = moment(endDate);

  return discharge.diff(admission, diffIn as 'd');
};

export const setHoursToNoon = (value: Date) => {
  return moment(value).hours(12).minutes(0).milliseconds(0).toDate();
};

export const setHoursToUTCNoon = (value: Date) => {
  return moment.utc(value).hours(12).minutes(0).milliseconds(0).toDate();
};

export const isNoon = (value: any) => {
  return value ? moment(value).hours() === 12 && moment(value).minutes() === 0 : false;
};

export const prettyMillisecond = (milliseconds: number) => {
  if (!milliseconds) {
    return '';
  }

  const year = Math.floor(milliseconds / 1000.0 / 60.0 / 60.0 / 24.0 / 30.0 / 365.0);
  if (year >= 1) {
    return `${year} year${year > 1 ? 's' : ''}`;
  }

  const month = Math.floor(milliseconds / 1000.0 / 60.0 / 60.0 / 24.0 / 30);
  if (month >= 1) {
    return `${month} month${month > 1 ? 's' : ''}`;
  }

  const day = Math.floor(milliseconds / 1000.0 / 60.0 / 60.0 / 24.0);
  if (day >= 1) {
    return `${day} day${day > 1 ? 's' : ''}`;
  }

  const hour = Math.floor(milliseconds / 1000.0 / 60.0 / 60.0);
  if (hour >= 1) {
    return `${hour} hour${hour > 1 ? 's' : ''}`;
  }

  const minute = Math.floor(milliseconds / 1000.0 / 60.0);
  if (minute >= 1) {
    return `${minute} minute${minute > 1 ? 's' : ''}`;
  }

  const second = Math.floor(milliseconds / 1000.0);
  if (second >= 1) {
    return `${second} second${second > 1 ? 's' : ''}`;
  }

  return `${milliseconds} millisecond${milliseconds > 1 ? 's' : ''}`;
};

export const toStartDate = (value: string) => {
  if (!value) {
    return value;
  }

  // eslint-disable-next-line
  return moment(value).hour(0).minute(0).second(0).millisecond(0).toISOString()
};

export const toEndDate = (value: string) => {
  if (!value) {
    return value;
  }

  // eslint-disable-next-line
  return moment(value).hour(23).minute(59).second(59).millisecond(999).toISOString()
};

export const roundToNearestHour = (date: Date) => {
  date.setMinutes(date.getMinutes() + 30);
  date.setMinutes(0, 0, 0);

  return date;
};

export const isSameOrBeforeDate = (date1: Date, date2: Date) => {
  return moment(date1).isSameOrBefore(date2, 'day');
};

export const isSameOrAfterDate = (date1: Date, date2: Date) => {
  return moment(date1).isSameOrAfter(date2, 'day');
};

const formatUnit = (unit: string, value: number) => {
  if (unit.length === 1) {
    return `${value}${unit}`;
  }

  return `${value} ${value > 1 ? `${unit}s` : unit}`;
};

const formatTwoUnits = (unit1: string, value1: number, unit2: string, value2: number) => {
  if (value1 < 1) {
    return '';
  }

  const unit1Str = formatUnit(unit1, value1);
  const unit2Str = value2 >= 1 ? ` ${formatUnit(unit2, value2)}` : '';

  return `${unit1Str}${unit2Str}`;
};

export const formatDuration = (totalSeconds: number) => {
  if (!totalSeconds) {
    return '-';
  }
  const duration = Duration.fromObject({ seconds: Math.floor(totalSeconds) })
    .rescale()
    .toObject();

  const { years = 0, months = 0, weeks = 0, hours = 0, minutes = 0, seconds = 0 } = duration;

  const days = (duration.days ?? 0) + weeks * 7;

  if (years >= 1) {
    return formatTwoUnits('year', years, 'month', months);
  }

  if (months >= 1) {
    return formatTwoUnits('month', months, 'd', days);
  }

  if (days >= 1) {
    return formatTwoUnits('d', days, 'h', hours);
  }

  if (hours >= 1) {
    return formatTwoUnits('h', hours, 'm', minutes);
  }

  if (minutes >= 1) {
    return formatTwoUnits('m', minutes, 's', seconds);
  }

  return `${Math.max(1, seconds)}s`;
};

export const formatDateTime = (date: string) => {
  return DateTime.fromISO(date).toFormat('M/d/yy, h:mma');
};
