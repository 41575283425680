import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { StateMessege } from '@common';
import { useMap } from 'react-use';
import { Filters as TFilters, UsersOption } from '@components/Project/Activity/types';
import { useActivity } from '@hooks/useActivity';
import { find } from 'lodash/fp';
import { flatPagesData } from '@utils/reactQuery';
import { useIntersectionObserver } from '@react-hookz/web/esm/useIntersectionObserver';
import { Tabs } from '@kit/ui/Tabs';
import {
  CallEventType,
  EmailEventType,
  EntityType,
  FileEventType,
  PortalEventType,
  ProjectEventType,
  ReactQueryKey,
  SmsEventType,
  TaskEventType
} from '@enums';
import { Filter as FilterIcon } from 'react-feather';
import { colors } from '@styles';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { QueryParamsEnum, useQueryParam, useQueryParamMutation } from '@hooks/useQueryParam';
import { useUnmountEffect, useUpdateEffect } from '@react-hookz/web';
import { useQueryClient } from 'react-query';
import { useAppSelector } from '@hooks/store';
import { isCustomerPortalEnabled } from '@state/selectors';
import { PortalMessageForm } from '@features/ClientPortfolio/Client/CreateEventsPanel/forms/PortalMessageForm';
import { useRecordDetail } from '@hooks/useRecordDetail';
import { useAuth } from '@hooks';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils';
import {
  Container,
  Feed,
  FilterIconWithArrow,
  FiltersAndSearch,
  FiltersContainer,
  HistoryContainer,
  InfiniteLoaderNode,
  InlineFormContainer,
  TabsAndFilters
} from './styled';
import { Filters } from './Filters';
import { FeedItem } from './FeedItem';
import { Search } from './Search';
import { PinnedComments } from './PinnedComments';

const userIdsOptionToIds = (values: UsersOption[]) => {
  return values.flatMap((item) => {
    if ('team' in item && item.isTeam) {
      return item.team.teamUsers.filter((user) => !find({ id: `user-${user.id}` }, values)).map(({ id }) => id);
    }

    return item.rawId;
  });
};

const TABS = [
  {
    id: 'all',
    title: 'All'
  },
  {
    id: 'calls',
    title: 'Calls',
    types: [
      {
        entity: EntityType.CALL,
        event: CallEventType.CALL_CREATED
      }
    ]
  },
  {
    id: 'sms',
    title: 'Sms',
    types: [
      {
        entity: EntityType.SMS,
        event: SmsEventType.RECEIVED
      },
      {
        entity: EntityType.SMS,
        event: SmsEventType.SENT
      }
    ]
  },
  {
    id: 'email',
    title: 'Emails',
    types: [
      {
        entity: EntityType.EMAIL,
        event: EmailEventType.RECEIVED
      },
      {
        entity: EntityType.EMAIL,
        event: EmailEventType.SENT
      }
    ]
  },
  {
    id: 'comment',
    title: 'Comments',
    types: [
      {
        entity: EntityType.PROJECT,
        event: ProjectEventType.COMMENTED
      },
      {
        entity: EntityType.TASK,
        event: TaskEventType.COMMENTED
      },
      {
        entity: EntityType.FILE,
        event: FileEventType.COMMENTED
      }
    ]
  },
  {
    id: 'log',
    title: 'Log',
    notTypes: [
      {
        entity: EntityType.SMS,
        event: SmsEventType.RECEIVED
      },
      {
        entity: EntityType.SMS,
        event: SmsEventType.SENT
      },
      {
        entity: EntityType.EMAIL,
        event: EmailEventType.RECEIVED
      },
      {
        entity: EntityType.EMAIL,
        event: EmailEventType.SENT
      },
      {
        entity: EntityType.PROJECT,
        event: ProjectEventType.COMMENTED
      },
      {
        entity: EntityType.TASK,
        event: TaskEventType.COMMENTED
      },
      {
        entity: EntityType.FILE,
        event: FileEventType.COMMENTED
      }
    ]
  }
];

/*
TODO refactor this component
it is mostly copy-pasted from src/components/Project/Activity
*/

interface Props {
  recordId: number;
  context?: 'project' | 'client';
}

export const History = ({ recordId, context = 'project' }: Props) => {
  const [tab, setTab] = useState(TABS[0]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const { data: record } = useRecordDetail(recordId, { refetchOnMount: false });

  const [filters, { set: setFilter }] = useMap<TFilters>({
    search: '',
    types: [],
    stageIds: [],
    propertyIds: [],
    userIds: [],
    startDate: undefined,
    endDate: undefined
  });

  const loadingNodeRef = useRef<HTMLDivElement>();
  const loadingTopNodeRef = useRef<HTMLDivElement>();

  const loadingRef = useRef<boolean>(false);
  const loadingPrevRef = useRef<boolean>(false);

  const isLoadPreviousEnabled = useRef<boolean>(true);
  const isScrolledToFeedItem = useRef<boolean>(false);

  const { setParams } = useQueryParamMutation();

  const [feedCursor] = useQueryParam(QueryParamsEnum.FeedCursor);
  const [feedId] = useQueryParam(QueryParamsEnum.FeedId);

  const entry = useIntersectionObserver(loadingNodeRef.current);
  const topEntry = useIntersectionObserver(loadingTopNodeRef.current);
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);
  const queryClient = useQueryClient();

  const { user } = useAuth();
  const { data: access } = useUserRoleSettings(record?.companyId, user?.userId);
  const canCreateComment = hasAccess(access, 'comment', 'create');

  const showPortalTab = isPortalEnabled;

  const clientId = context === 'client' ? recordId : record?.account?.id;
  const tabs = useMemo(() => {
    if (!showPortalTab) {
      return TABS;
    }

    return [
      ...TABS,
      {
        id: 'portal',
        title: 'Portal',
        types: [
          {
            entity: EntityType.PORTAL,
            event: PortalEventType.COMMUNICATION
          }
        ]
      }
    ];
  }, [showPortalTab]);

  useUpdateEffect(() => {
    setParams(
      {
        [QueryParamsEnum.FeedCursor]: undefined,
        [QueryParamsEnum.FeedId]: undefined
      },
      true
    );
  }, [filters, tab, recordId]);

  const { data, isLoading, fetchNextPage, fetchPreviousPage, hasNextPage, hasPreviousPage } = useActivity({
    recordId: tab.id === 'portal' ? clientId : recordId,
    ...filters,
    types: tab.types,
    notTypes: tab.notTypes,
    userIds: userIdsOptionToIds(filters.userIds),
    equalOrBeforeDate: feedCursor ? decodeURIComponent(feedCursor) : undefined
  });

  useUpdateEffect(() => {
    if (feedCursor) {
      isScrolledToFeedItem.current = false;

      queryClient.removeQueries([ReactQueryKey.ProjectActivity]);
    }
  }, [feedCursor, queryClient]);

  useUnmountEffect(() => {
    if (feedCursor) {
      queryClient.removeQueries([ReactQueryKey.ProjectActivity]);

      setParams(
        {
          [QueryParamsEnum.FeedCursor]: undefined,
          [QueryParamsEnum.FeedId]: undefined
        },
        true
      );
    }
  });

  const flatFeed = useMemo(() => {
    return flatPagesData(data);
  }, [data]);

  useEffect(() => {
    if (entry?.isIntersecting && hasNextPage) {
      if (!loadingRef.current) {
        loadingRef.current = true;
        fetchNextPage().finally(() => {
          setTimeout(() => {
            loadingRef.current = false;
          }, 0);
        });
      }
    }
  }, [entry, flatFeed, data, fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (feedId && feedCursor && topEntry?.isIntersecting && hasPreviousPage && isLoadPreviousEnabled.current) {
      if (!loadingPrevRef.current) {
        loadingPrevRef.current = true;
        fetchPreviousPage().finally(() => {
          loadingPrevRef.current = false;

          const node = document.getElementById(`feed_${feedId}`);

          if (node && !isScrolledToFeedItem.current) {
            isLoadPreviousEnabled.current = false;
            node.scrollIntoView();
            isScrolledToFeedItem.current = true;

            setTimeout(() => {
              isLoadPreviousEnabled.current = true;
            }, 1000);
          }
        });
      }
    }
  }, [feedId, feedCursor, topEntry, flatFeed, data, fetchPreviousPage, hasPreviousPage]);

  const toggleFilters = useCallback(() => {
    setIsFiltersOpen((prev) => !prev);
  }, []);

  return (
    <HistoryContainer>
      <TabsAndFilters>
        <Tabs variant="outline" tabs={tabs} selected={tab.id} onChange={setTab} />
        <FiltersAndSearch>
          <FilterIconWithArrow onClick={toggleFilters}>
            <FilterIcon size={14} color={colors.green} />
            {isFiltersOpen ? (
              <ChevronUpIcon size={16} color={colors.green} />
            ) : (
              <ChevronDownIcon size={16} color={colors.green} />
            )}
          </FilterIconWithArrow>
          <Search setFilter={setFilter} />
        </FiltersAndSearch>
      </TabsAndFilters>

      {isFiltersOpen && (
        <FiltersContainer>
          <Filters filters={filters} setFilter={setFilter} />
        </FiltersContainer>
      )}

      {tab.id === 'portal' && canCreateComment && (
        <InlineFormContainer>
          <PortalMessageForm expandSize="small" size="small" recordId={clientId} />
        </InlineFormContainer>
      )}

      <Container>
        <Feed>
          {(tab.id === 'all' || tab.id === 'comment') && (
            <PinnedComments
              clientId={context === 'client' ? clientId : undefined}
              projectId={context !== 'client' ? recordId : undefined}
            />
          )}

          {flatFeed.length === 0 && !isLoading ? (
            <StateMessege subTitle="Looks like there's no activity here yet." />
          ) : (
            <>
              <InfiniteLoaderNode ref={loadingTopNodeRef}>&nbsp;</InfiniteLoaderNode>
              {flatFeed.map((item) => (
                <FeedItem contextEntityId={recordId} context={context} key={item.id} item={item} />
              ))}
              <InfiniteLoaderNode ref={loadingNodeRef}>&nbsp;</InfiniteLoaderNode>
            </>
          )}
        </Feed>
      </Container>
    </HistoryContainer>
  );
};
