import React from 'react';

export const EditIcon = ({
  size = '16px',
  color = 'currentColor',
  className
}: {
  size?: string | number;
  color?: string;
  className?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66536 10.9428V13.3333H5.05589L13.0559 5.3333L10.6654 2.94278L2.66536 10.9428ZM10.6654 1.99997L10.194 1.52856L1.52729 10.1952C1.40227 10.3203 1.33203 10.4898 1.33203 10.6666V14C1.33203 14.3682 1.63051 14.6666 1.9987 14.6666H5.33203C5.50884 14.6666 5.67841 14.5964 5.80344 14.4714L14.4701 5.80471C14.7305 5.54436 14.7305 5.12225 14.4701 4.8619L11.1368 1.52856L10.6654 1.99997Z"
      fill={color}
    />
  </svg>
);
