import { useCallback } from 'react';
import { find, get } from 'lodash';

import { UserRole } from '@enums';
import { useAppSelector } from '@hooks/store';
import { selectCompanyIdBySelectedPage, selectCurrentUser, selectUserRolesByCompanyId } from '@state/selectors';

export { User as AuthUser } from '@state/reducers/authentication/types';
export type IsMe = (person: { id: number }) => boolean;

export const useAuth = (companyId?: number) => {
  const user = useAppSelector(selectCurrentUser);
  const companyIdFromPage = useAppSelector(selectCompanyIdBySelectedPage);
  const isMe: IsMe = useCallback((person) => get(person, 'id') === user.userId, [user.userId]);

  const roles = useAppSelector((state) => selectUserRolesByCompanyId(state, companyId ?? companyIdFromPage));
  const role = roles?.[0];

  const isOwner = !!find(roles, { name: UserRole.OWNER });
  const isAdmin = isOwner || !!find(roles, { name: UserRole.ADMIN });
  const isManager = isAdmin || !!find(roles, { name: UserRole.PROJECT_MANAGER });
  const isWorker = isManager || !!find(roles, { name: UserRole.WORKER });
  const isSales = isWorker || !!find(roles, { name: UserRole.SALES });

  return {
    user,
    role,
    isMe,
    isAdmin,
    isManager,
    isWorker,
    isSales,
    isOwner
  };
};
