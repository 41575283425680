import { RecordDocListItem } from '@hooks/documents/useRecordDocList';
import React, { useCallback, useRef, useState } from 'react';
import { DrawLineIcon } from '@kit/ui/icons/DrawLineIcon';
import { Archive, File, FileText, MessageSquare, Play } from 'react-feather';
import { ContextMenu } from '@kit/components/ContextMenu';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import moment from 'moment';
import { UserAvatar } from '@kit/components/UserAvatar';
import { fileSizeInMB, isVideo } from '@utils/files';
import { cutFileName } from '@utils/utils';
import { Checkbox } from '@kit/ui/Checkbox';
import { RecordType } from '@types';
import { Badge } from '@kit/ui/Badge';
import { ContactAvatar } from '@kit/components/ContactAvatar';
import { parseUtcDate } from '@utils/dates';
import { CreatedSource } from '@generated/types/graphql';
import { useDocContextMenuItems } from '../../useDocContextMenuItems';
import {
  Badges,
  Cell,
  CheckboxContainer,
  CommentsCount,
  CommentStats,
  DocRow,
  FileIconThumbnail,
  FileImageThumbnail,
  FileNameAndIcon,
  FileNameCell,
  FileSource,
  ImageAndCheckboContainer,
  User,
  VideoBadge
} from './styled';
import { SelectionType } from '../../GroupedDocList/useSelection';
import { ConnectIntegrationType } from '@features/Platform/Integrations';
import { ConnectIntegrationBrand } from '@features/Platform/Integrations/Common/ConnectIntegrationBrand/ConnectIntegrationBrand';

interface Props {
  doc: RecordDocListItem;
  recordType: RecordType;
  isSelected: boolean;
  toggleItem: (doc: RecordDocListItem, meta: SelectionType) => void;
  onClick: (doc: RecordDocListItem) => void;
}

export const DocListItem = ({ doc, isSelected, toggleItem, recordType, onClick }: Props) => {
  const downloadFileRef = useRef<HTMLAnchorElement>(null);

  const contextMenuItems = useDocContextMenuItems({ doc, recordType });
  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);

  const handleCheckboxClick = useCallback(
    (e) => {
      toggleItem(doc, { withShift: e.shiftKey, withCtrl: e.ctrlKey });
    },
    [toggleItem, doc]
  );

  const handleContextMenu = useCallback<React.MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();

    setContextMenuPosition({
      x: e.pageX,
      y: e.pageY
    });
  }, []);

  const handleRowClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (e.shiftKey || e.ctrlKey) {
        toggleItem(doc, { withShift: e.shiftKey, withCtrl: e.ctrlKey });

        return;
      }

      onClick(doc);
    },
    [doc, onClick, toggleItem]
  );

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  const stopPropagation = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  const propertyName = doc.projectColumnValueFileProjectColumnValue?.projectColumnValue?.column?.name;

  const fileFormName = doc.valueFile?.name;

  const hasBadges = propertyName || fileFormName || doc.sharedWithPortal || doc.createdByContact || doc.sourceLog;

  return (
    <DocRow
      onClick={handleRowClick}
      onContextMenu={handleContextMenu}
      isArchived={doc.isArchived}
      isSelected={isSelected}
    >
      <Cell width={40}>
        <ImageAndCheckboContainer>
          {Boolean(!doc.form && doc.metaData?.thumbnailUrl) && (
            <FileImageThumbnail src={doc.metaData.thumbnailUrl} size={40} />
          )}
          {Boolean(!doc.form && !doc.metaData?.thumbnailUrl) && (
            <FileIconThumbnail size={40}>
              <File size="24px" color="#9C9CAA" />
            </FileIconThumbnail>
          )}
          {Boolean(doc.form) && (
            <FileIconThumbnail size={40}>
              <FileText size="24px" color="#9C9CAA" />
            </FileIconThumbnail>
          )}

          {isVideo(doc) && (
            <VideoBadge color="#1d1d35" bgColor="#fff">
              <Play size="12px" color="#1d1d35" />
            </VideoBadge>
          )}
          <CheckboxContainer onClick={stopPropagation}>
            <Checkbox isChecked={isSelected} onClick={handleCheckboxClick} />
          </CheckboxContainer>
        </ImageAndCheckboContainer>
      </Cell>
      <FileNameCell>
        <FileNameAndIcon>
          {doc.isArchived && <Archive color="#9C9CAA" size="16px" />}
          {cutFileName(doc.name, 50)}
        </FileNameAndIcon>

        {hasBadges && (
          <Badges>
            {Boolean(fileFormName || propertyName) && (
              <Badge color="#828D9A" bgColor="#E4E8EC">
                {fileFormName || propertyName}
              </Badge>
            )}
            {!doc.createdByContact && doc.sharedWithPortal && (
              <Badge color="#828D9A" bgColor="#fff">
                Shared
              </Badge>
            )}
            {doc.createdByContact && (
              <Badge bgColor="#FCF2DB" color="#1D1D35">
                From Client
              </Badge>
            )}
            {doc.sourceLog && (
              <FileSource>
                <ConnectIntegrationBrand type={doc.sourceLog.integration.type as ConnectIntegrationType} />
              </FileSource>
            )}
          </Badges>
        )}
      </FileNameCell>
      <Cell width={80}>
        <CommentStats>
          {Boolean(doc.annotations) && <DrawLineIcon size="16px" color="#9C9CAA" />}
          {doc.commentsByFileIdConnection.totalCount > 0 && (
            <CommentsCount>
              <MessageSquare size="16px" color="#9C9CAA" />
              {doc.commentsByFileIdConnection.totalCount}
            </CommentsCount>
          )}
        </CommentStats>
      </Cell>
      <Cell width={150}>{moment(parseUtcDate(doc.updatedAt)).format('MM/DD/YYYY, h:mm a')}</Cell>
      <Cell width={170}>
        {doc.createdByUser && (
          <User>
            <UserAvatar size={20} user={doc.createdByUser} />
            {doc.createdByUser.firstName} {doc.createdByUser.lastName}
          </User>
        )}
        {doc.createdByContact && (
          <User>
            <ContactAvatar size={20} contact={doc.createdByContact} />
            {doc.createdByContact.name}
          </User>
        )}
      </Cell>
      <Cell width={170}>{doc.source === CreatedSource.ConnectIntegration ? 'Aurora Solar' : ''}</Cell>
      <Cell width={60}>{doc.metaData?.size ? `${fileSizeInMB(doc.metaData?.size).toFixed(2)} mb` : null}</Cell>
      <Cell width={24} onClick={stopPropagation}>
        <ContextMenu point={contextMenuPosition} items={contextMenuItems} onClose={handleCloseContextMenu}>
          {
            // eslint-disable-next-line
          }
          <a ref={downloadFileRef} />
          <MoreVerticalIcon size="24px" color="#9C9CAA" />
        </ContextMenu>
      </Cell>
    </DocRow>
  );
};
