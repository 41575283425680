import React, { FC } from 'react';
import { ConnectColumnMapping } from '@generated/types/graphql';
import { Container, PropertyPath, SubTitle, Table } from './styled';
import { valuePathToName } from './constants';
import { ConnectIntegrationName, ConnectIntegrationType } from '@features/Platform/Integrations';

interface HistoryProps {
  integrationType: ConnectIntegrationType;
  mappings: ConnectColumnMapping[];
}

export const StandardPropertiesMapping: FC<HistoryProps> = ({ integrationType, mappings }) => {
  return (
    <Container>
      <SubTitle>Default mapping</SubTitle>

      <Table>
        <thead>
          <tr>
            <th style={{ width: '50%' }}>{ConnectIntegrationName[integrationType]} Field</th>
            <th style={{ width: '50%' }}>Coperniq Property</th>
          </tr>
        </thead>
        <tbody>
          {mappings
            .filter((mapping) => mapping.standard)
            .map((mapping) => (
              <tr key={mapping.columnId}>
                <td style={{ width: '50%' }} dangerouslySetInnerHTML={{ __html: valuePathToName(mapping.valuePath) }} />
                <td style={{ width: '50%' }}>
                  <PropertyPath>{mapping.column.name}</PropertyPath>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};
