import React from 'react';
import { ConnectIntegrationType } from '../../types';
import Aurora from '@assets/svg/aurora2.svg';
import OpenSolar from '@assets/svg/opensolar2.svg';
import { ConnectIntegrationName } from '@features/Platform/Integrations';

type Props = {
  type: ConnectIntegrationType;
};

export const ConnectIntegrationBrand = ({ type }: Props) => {
  return (
    <>
      {(type === ConnectIntegrationType.AURORA_SOLAR && (
        <img src={Aurora} width={20} height={20} alt="Aurora Solar" />
      )) ||
        (type === ConnectIntegrationType.OPEN_SOLAR && <img src={OpenSolar} width={20} height={20} alt="OpenSolar" />)}

      <span>{ConnectIntegrationName[type]}</span>
    </>
  );
};
