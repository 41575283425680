import { ProjectStatus } from '@generated/types/graphql';
import { AggregateColumn, FilterOperators } from '@types';
import { DeepPartial } from 'redux';

export enum AnalyticsModule {
  CLIENTS = 'clients',
  REQUESTS = 'requests',
  PROJECTS = 'projects',
  WORK_ORDERS = 'workOrders'
}

export enum AnalyticsWidgetType {
  KPI = 'kpi',
  BAR = 'bar',
  LINE = 'line',
  FUNNEL = 'funnel',
  TIMELINE = 'timeline',
  PIPELINE = 'pipeline',
  PIE = 'pie',
  DOUGHNUT = 'doughnut',
  LEADERBOARD = 'leaderboard'
}

export enum AnalyticsWidgetAggregateFunction {
  COUNT = 'count',
  SUM = 'sum',
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max'
}

export enum AnalyticsSubmeasureType {
  ENTERED = 'entered',
  EXITED = 'exited'
}

export enum AnalyticsMeasureEntityType {
  CLIENT = 'client',
  REQUEST = 'request',
  PROJECT = 'project',
  WORK_ORDER = 'workOrder',
  VISIT = 'visit'
}

export enum PredefinedMeasureId {
  WORK_ORDERS = 'workOrderId',
  VISITS = 'visitId',
  VISITS_PER_WORK_ORDER = 'visitsPerWorkOrder',
  CLIENTS = 'clientId',
  REQUESTS = 'requestId',
  REQUEST_VALUE = 'requestValue',
  REQUEST_SIZE = 'requestSize',
  PROJECTS = 'projectId',
  PROJECT_VALUE = 'projectValue',
  PROJECT_SIZE = 'projectSize',
  TIME_TO_COMPLETE = 'timeToComplete'
}

export type MeasureOption = {
  value: string;
  label: string;

  property: {
    entityType: AnalyticsMeasureEntityType;
    id: number;
  };
};

export enum StandardPropertyId {
  ID = 0,
  UID = -47,
  REQUEST_VALUE = -27,
  REQUEST_SIZE = -42,
  PROJECT_VALUE = -43,
  PROJECT_SIZE = -44,
  CLIENT_STATUS = -35,
  CLIENT_TYPE = -29,
  LAST_OUTBOUND_CALL_DISPOSITION = -53,
  LAST_INBOUND_CALL_DISPOSITION = -54,
  STAGE = -25,
  TRADES = -34,
  REQUEST_STATUS = -48,
  PROJECT_STATUS = -46,
  CREATED_AT = -5,
  CREATED_BY = -6,
  OWNER = -31,
  PROJECT_MANAGER = -41,
  SALES_REP = -40,
  WORKFLOW = -24,
  SITE_ADDRESS = -3
}

export enum DimensionType {
  TIME = 'time',
  DROPDOWN_PROPERTY = 'dropdownProperty',
  CREATOR = 'creator',
  OWNER = 'owner',
  PROJECT_MANAGER = 'projectManager',
  SALES_REP = 'salesRep',
  PRODUCT_CATEGORY = 'productCategory',
  TEMPLATE = 'template',
  PRIORITY = 'priority',
  ASSIGNEE = 'assignee',
  LABEL = 'label',
  STATUS = 'status'
}

export type RecordDimensionType =
  | DimensionType.TIME
  | DimensionType.DROPDOWN_PROPERTY
  | DimensionType.CREATOR
  | DimensionType.OWNER
  | DimensionType.PROJECT_MANAGER
  | DimensionType.SALES_REP
  | DimensionType.PRODUCT_CATEGORY;

export type WorkOrderDimensionType =
  | DimensionType.TIME
  | DimensionType.TEMPLATE
  | DimensionType.PRIORITY
  | DimensionType.ASSIGNEE
  | DimensionType.LABEL
  | DimensionType.STATUS;

export enum PositiveTrendType {
  UP = 'up',
  DOWN = 'down'
}

export enum AnalyticsWidgetDateRangeType {
  ALL_TIME = 'allTime',
  CUSTOM = 'custom',
  CUSTOM_TO_DATE = 'customToDate',
  TODAY = 'today',
  THIS_WEEK_TO_DATE = 'thisWeekToDate',
  THIS_MONTH_TO_DATE = 'thisMonthToDate',
  THIS_QUARTER_TO_DATE = 'thisQuarterToDate',
  THIS_YEAR_TO_DATE = 'thisYearToDate',
  THIS_WEEK = 'thisWeek',
  THIS_MONTH = 'thisMonth',
  THIS_QUARTER = 'thisQuarter',
  THIS_YEAR = 'thisYear',
  YESTERDAY = 'yesterday',
  LAST_WEEK = 'lastWeek',
  LAST_MONTH = 'lastMonth',
  LAST_QUARTER = 'lastQuarter',
  LAST_YEAR = 'lastYear',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  LAST_60_DAYS = 'last60Days',
  LAST_90_DAYS = 'last90Days',
  LAST_180_DAYS = 'last180Days',
  LAST_365_DAYS = 'last365Days',
  NEXT_WEEK = 'nextWeek',
  NEXT_MONTH = 'nextMonth',
  NEXT_QUARTER = 'nextQuarter',
  NEXT_YEAR = 'nextYear',
  NEXT_7_DAYS = 'next7Days',
  NEXT_30_DAYS = 'next30Days',
  NEXT_60_DAYS = 'next60Days',
  NEXT_90_DAYS = 'next90Days',
  NEXT_180_DAYS = 'next180Days',
  NEXT_365_DAYS = 'next365Days'
}

export enum ShowFilterType {
  ALL = 'all',
  ACTIVE = 'active',
  ARCHIVED = 'archived'
}

export type WidgetSettings = {
  title: string;
  subtitle: string;
  module: AnalyticsModule;
  widgetType: AnalyticsWidgetType;
  aggregateFunctionId?: AnalyticsWidgetAggregateFunction;
  measure?: { id: number; entityType: AnalyticsMeasureEntityType };
  submeasureTypeId?: AnalyticsSubmeasureType;
  submeasureId?: number | string;
  dimensionId?: DimensionType;
  subdimensionId?: string;
  positiveTrendType?: PositiveTrendType;
  workflowId?: number;
  dateRangeType: AnalyticsWidgetDateRangeType;
  dateRangeStart?: string;
  dateRangeEnd?: string;
  show?: ShowFilterType;
  filters?: WidgetFilters;
  includedTimelineStatuses?: ProjectStatus[];
};

export enum TimeGranularity {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year'
}

export enum UserSubdimentionType {
  USER = 'user',
  TEAM = 'team'
}

export enum FilterGroupOperator {
  AND = 'AND',
  OR = 'OR'
}

export enum PredefinedWidgetFilterFieldId {
  // filters for records
  WORKFLOW = 'workflowId',
  WORK_ORDER_WITH_TITLE = AggregateColumn.TASK_WITH_TITLE,
  // filters for work orders
  WORK_ORDER_TYPE = 'workOrderTypeId',
  WORK_ORDER_TEMPLATE = 'workOrderTemplateId',
  WORK_ORDER_TITLE = 'workOrderTitle',
  WORK_ORDER_STATUS = 'workOrderStatus',
  WORK_ORDER_PRIORITY = 'workOrderPriority',
  WORK_ORDER_LABEL = 'workOrderLabel',
  WORK_ORDER_ASSIGNEE = 'workOrderAssigneeId',
  WORK_ORDER_CREATED_AT = 'workOrderCreatedAt',
  WORK_ORDER_DESCRIPTION = 'workOrderDescription',
  WORK_ORDER_COMPLETED_AT = 'workOrderCompletedAt',
  WORK_ORDER_START_DATE = 'workOrderStartDate',
  WORK_ORDER_END_DATE = 'workOrderEndDate',
  WORK_ORDER_NEXT_VISIT_DATE = 'workOrderNextVisitDate'
}

export type WorkOrderFilterFieldId =
  | PredefinedWidgetFilterFieldId.WORK_ORDER_TYPE
  | PredefinedWidgetFilterFieldId.WORK_ORDER_TEMPLATE
  | PredefinedWidgetFilterFieldId.WORK_ORDER_TITLE
  | PredefinedWidgetFilterFieldId.WORK_ORDER_STATUS
  | PredefinedWidgetFilterFieldId.WORK_ORDER_PRIORITY
  | PredefinedWidgetFilterFieldId.WORK_ORDER_LABEL
  | PredefinedWidgetFilterFieldId.WORK_ORDER_ASSIGNEE
  | PredefinedWidgetFilterFieldId.WORK_ORDER_CREATED_AT
  | PredefinedWidgetFilterFieldId.WORK_ORDER_DESCRIPTION
  | PredefinedWidgetFilterFieldId.WORK_ORDER_COMPLETED_AT
  | PredefinedWidgetFilterFieldId.WORK_ORDER_START_DATE
  | PredefinedWidgetFilterFieldId.WORK_ORDER_END_DATE
  | PredefinedWidgetFilterFieldId.WORK_ORDER_NEXT_VISIT_DATE;

export type WidgetFilter = {
  id: string;
  fieldId: PredefinedWidgetFilterFieldId | number;
  operator: FilterOperators;
  value: unknown;
};

export type WidgetFilters = {
  id: string;
  operator: FilterGroupOperator;
  children: (WidgetFilters | WidgetFilter)[];
};

export type DrilldownFilter<TFilterType extends Object = {}, TConditionType extends Object = {}> = {
  filter?: DeepPartial<TFilterType> | null;
  condition?: DeepPartial<TConditionType> | null;
};
