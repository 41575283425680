import styled from 'styled-components';
import { Popover } from '@kit/ui/Popover';

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const ContextMenuPopover = styled(Popover)`
  && {
    .MuiPaper-root > * {
      padding: 12px 0;
    }
  }
`;
