import { DrilldownFilter, WidgetSettings } from '@features/Analytics/types';
import { postGraphql } from '@services/api/base/graphql';
import { TaskReport, TaskReportCondition, TaskReportFilter, TaskReportsConnection } from '@generated/types/graphql';
import { gql } from 'graphql-request';
import { DeepPartial } from 'redux';
import { FetchDataPreviewFn } from '../types';
import {
  buildRequestParams,
  buildSubmeasureCondition,
  buildVisitCondition,
  buildTimelineCondition,
  buildTimelineFilter
} from './helpers';
import { RequestCacheType } from '../../../../enums';

export const fetchWorkOrdersDataPreview: FetchDataPreviewFn<
  TaskReport,
  TaskReportFilter,
  TaskReportCondition
> = async ({
  companyId,
  settings,
  page,
  perPage,
  drilldownFilter
}: {
  companyId: number;
  settings: WidgetSettings;
  page: number;
  perPage: number;
  drilldownFilter?: DrilldownFilter<TaskReportFilter, TaskReportCondition>;
}): Promise<{ reports: TaskReport[]; page: number; totalCount: number }> => {
  const filter: DeepPartial<TaskReportFilter> = {
    ...buildRequestParams({ companyId, settings }).filter,
    ...buildTimelineFilter(settings),
    ...(drilldownFilter?.filter ? drilldownFilter.filter : {})
  };

  const condition: DeepPartial<TaskReportCondition> = {
    ...buildSubmeasureCondition(settings),
    ...buildVisitCondition(settings),
    ...buildTimelineCondition(settings),
    ...(drilldownFilter?.condition ? drilldownFilter.condition : {})
  };

  const { taskReportsConnection } = await postGraphql<{ taskReportsConnection: TaskReportsConnection }>(
    gql`
      query WORK_ORDERS_DATA_PREVIEW_QUERY(
        $filter: TaskReportFilter!
        $condition: TaskReportCondition
        $offset: Int
        $first: Int
      ) {
        taskReportsConnection(filter: $filter, condition: $condition, offset: $offset, first: $first) {
          totalCount

          nodes {
            id
            visitId
            visitStartDate
            timelineStatusStartedAt
            timelineStatusEndedAt
            timelineStatus
            timelineStatusSpentTime
            completionTimeInS

            task {
              id
              uid
              isArchived
              title
              isField
              priority
              description
              createdAt
              completionDate
              startDate
              startDateAllDay
              endDate
              endDateAllDay
              templateTask {
                title
              }

              taskStatus {
                id
                label
              }
              assignee {
                id
                firstName
                lastName
                avatarUrl
              }
              project {
                id
                type
                title
              }
              privilegedTaskLabels(orderBy: POSITION_ASC) {
                label {
                  label
                  color
                }
              }

              taskVisitsByTaskId(orderBy: START_DATE_ASC) {
                id
                isCompleted
                startDate
              }
            }
          }
        }
      }
    `,
    {
      filter,
      condition,
      offset: page * perPage,
      first: perPage
    },
    undefined,
    { useReplica: true, cache: RequestCacheType.Long }
  );

  return {
    reports: taskReportsConnection.nodes,
    page,
    totalCount: taskReportsConnection.totalCount
  };
};
