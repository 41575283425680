import React, { useCallback, useState } from 'react';
import { List, Plus } from 'react-feather';
import { RecordType } from '@types';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useAppSelector, useAuth } from '@hooks';
import { useUserRoleSettings } from '@hooks/useRoles';
import { hasAccess } from '@utils';
import { selectWorkspaceId } from '@state/selectors';
import { Container, EventButtons, FormContainer, Header, WidgetIcon, WidgetTitle } from './styled';
import { EmailForm } from './forms/EmailForm';
import { SmsForm } from './forms/SmsForm';

import { CommentForm } from './forms/CommentForm';
import { CallForm } from './forms/CallForm';

interface Props {
  recordId: number;
  recordType: RecordType;
}

export const CreateEventsPanel = ({ recordId, recordType }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { user } = useAuth();
  const { data: access } = useUserRoleSettings(companyId, user.userId);
  const canCreateComment = hasAccess(access, 'comment', 'create');

  const actions = [
    {
      name: 'call',
      text: 'Call',
      formComponent: CallForm
    },
    {
      name: 'sms',
      text: 'SMS',
      formComponent: SmsForm
    },
    {
      name: 'email',
      text: 'Email',
      formComponent: EmailForm
    },
    canCreateComment && {
      name: 'comment',
      text: 'Comment',
      formComponent: CommentForm
    }
  ].filter(Boolean);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const clearEvent = useCallback(() => {
    setSelectedEvent(null);
  }, []);

  const handleEventButtonClick = useCallback(
    (event: (typeof actions)[number]) => () => {
      if (selectedEvent === event) {
        setSelectedEvent(null);

        return;
      }

      setSelectedEvent(event);
    },
    [selectedEvent]
  );

  return (
    <Container>
      <Header>
        <WidgetTitle>
          <WidgetIcon backgroundColor="#ECD4F0">
            <List size="16px" color="#B04EC2" />
          </WidgetIcon>
          Feed
        </WidgetTitle>
        <EventButtons>
          {actions.map((action) => (
            <Button variant={ButtonVariant.Secondary} key={action.name} onClick={handleEventButtonClick(action)}>
              <Plus size="16px" />
              {action.text}
            </Button>
          ))}
        </EventButtons>
      </Header>
      {Boolean(selectedEvent?.formComponent) && (
        <FormContainer>
          {React.createElement(selectedEvent.formComponent, { recordId, recordType, onClose: clearEvent })}
        </FormContainer>
      )}
    </Container>
  );
};
