import React, { useMemo } from 'react';
import { Form, FormValidationRules, InputField, RadioGroupField, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { ConnectTrigger } from '@generated/types/graphql';
import { useUpsertConnectTrigger } from '@hooks/workspace/connectIntegration';
import { SubDescription, SubTitle } from '../styled';
import { ConnectTriggerEvent, ConnectTriggerOptions } from '../../types';

type Props = {
  trigger: ConnectTrigger;
  triggerOptions: ConnectTriggerOptions;
  subTitle?: string;
};

interface FormValues {
  eventNameOption: ConnectTriggerEvent;
  eventValueRadio: string;
  eventValueText: string;
}

function getFormValues(trigger: ConnectTrigger, optionForTrigger?: ConnectTriggerEvent) {
  const { config } = trigger;

  return {
    eventNameOption: optionForTrigger,
    eventValueRadio: optionForTrigger?.options ? config.value : '',
    eventValueText: optionForTrigger?.options ? '' : config.value
  };
}

export const TriggerConfig = ({ trigger, triggerOptions, subTitle = 'Trigger' }: Props) => {
  const { mutateAsync: upsertTrigger } = useUpsertConnectTrigger();

  const { type, integrationId } = trigger;

  const typeOptions = useMemo(() => triggerOptions[type] || [], [triggerOptions, type]);
  const optionForTrigger = useMemo(
    () => typeOptions.find((option) => option.value === trigger.config.event),
    [trigger, typeOptions]
  );

  const { handleSubmit, form } = useForm<FormValues>({
    defaultValues: getFormValues(trigger, optionForTrigger),
    onSubmit: async ({ eventNameOption, eventValueRadio, eventValueText }) => {
      await upsertTrigger({
        integrationId,
        dto: {
          type,
          eventName: eventNameOption.value,
          eventValue: eventNameOption.options ? eventValueRadio : eventValueText
        }
      });
      reset({ eventNameOption, eventValueRadio, eventValueText });
    }
  });

  const {
    control,
    watch,
    formState: { isSubmitting, isDirty },
    reset
  } = form;

  const currentOption = watch('eventNameOption');
  const fieldToUse = currentOption?.options ? 'radio' : 'text';

  const RULES: FormValidationRules<FormValues> = {
    eventNameOption: {
      isRequired: true
    },
    eventValueRadio: {
      isRequired: fieldToUse === 'radio'
    },
    eventValueText: {
      isRequired: fieldToUse === 'text'
    }
  };

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <SubTitle>{subTitle}</SubTitle>
      <SubDescription>Select trigger</SubDescription>
      <SelectField
        label="Trigger"
        name="eventNameOption"
        control={control}
        options={typeOptions}
        getOptionSelected={(option: any, other: any) => option.value === other.value}
      />
      {fieldToUse === 'radio' && (
        <RadioGroupField
          label="Select status"
          name="eventValueRadio"
          control={control}
          options={(currentOption?.options || []).map((option) => ({ label: option.name, value: option.value }))}
          layout="row"
        />
      )}
      {fieldToUse === 'text' && (
        <InputField label="Status" name="eventValueText" control={control} placeholder="Type here" />
      )}
      <Button disabled={isSubmitting || !isDirty} type="submit" variant={ButtonVariant.Primary}>
        Update
      </Button>
    </Form>
  );
};
