import React, { useMemo } from 'react';
import { Flag } from 'react-feather';
import { Badge } from '@common/Badge';
import { defaultTheme } from '@themes';
import { ProjectDetail } from '@types';
import { getSlaStageColor, getTimeInStageInDays } from '@utils';

interface SlaFlagProps {
  record: ProjectDetail;
  className?: string;
}

const baseStyles = {
  color: defaultTheme.colors.white,
  font: defaultTheme.typefaces.text
};

const warningStyles = {
  backgroundColor: defaultTheme.colors.yellow,
  ...baseStyles
};
const alertStyles = {
  backgroundColor: defaultTheme.colors.red,
  ...baseStyles
};
const infoStyles = {
  ...baseStyles,
  backgroundColor: defaultTheme.colors.gray2Bg,
  color: defaultTheme.colors.black
};

export const SlaFlag: React.FC<SlaFlagProps> = ({ record, className }) => {
  const timeInStage = useMemo(() => getTimeInStageInDays(record), [record]);
  const slaStageColor = useMemo(() => getSlaStageColor(record), [record]);

  if (!timeInStage) {
    return null;
  }

  if (slaStageColor === 'NON_VIOLATED') {
    return (
      <Badge className={className} style={infoStyles}>
        <Flag color={defaultTheme.colors.black} />
        {timeInStage}d
      </Badge>
    );
  }

  if (slaStageColor === 'RED') {
    return (
      <Badge className={className} style={alertStyles}>
        <Flag color={defaultTheme.colors.white} />
        {timeInStage}d
      </Badge>
    );
  }

  if (slaStageColor === 'YELLOW') {
    return (
      <Badge className={className} style={warningStyles}>
        <Flag color={defaultTheme.colors.white} />
        {timeInStage}d
      </Badge>
    );
  }

  return null;
};
