import { postGraphql } from '@services/api/base/graphql';
import { ProjectReportsConnection } from '@generated/types/graphql';
import { gql } from 'graphql-request';
import { KpiData } from '../../Widget/types';
import { buildAggregationResponseFields, buildRequestParams, extractGroupAggregationValue } from './helpers';
import { RecordsWidgetDataParams } from '../types';
import { BuildRequestArgs } from './types';
import { calcDiffInDaysForDateRangeOption } from '../helpers';
import { RequestCacheType } from '../../../../enums';

const requestAggregatedData = ({
  companyId,
  settings,
  propertiesMap,
  calculatePreviousPeriod,
  teamsMap
}: BuildRequestArgs) => {
  const { filter, condition } = buildRequestParams({
    companyId,
    settings,
    propertiesMap,
    calculatePreviousPeriod,
    teamsMap
  });

  const aggregationResponseFields = buildAggregationResponseFields(settings, propertiesMap);

  return postGraphql<{ projectReportsConnection: ProjectReportsConnection }>(
    gql`
        query RECORDS_KPI_DATA_QUERY($filter: ProjectReportFilter!, $condition: ProjectReportCondition) {
            projectReportsConnection(filter: $filter, condition: $condition) {
                aggregates {
                    ${aggregationResponseFields}
                }
            }
        }
    `,
    {
      filter,
      condition
    },
    undefined,
    { useReplica: true, cache: RequestCacheType.Long }
  );
};

export const fetchRecordsKpiData = async ({
  companyId,
  settings,
  propertiesMap,
  teamsMap
}: RecordsWidgetDataParams): Promise<KpiData> => {
  const result = await requestAggregatedData({ companyId, settings, propertiesMap, teamsMap });
  const prevPeriodResult = await requestAggregatedData({
    companyId,
    settings,
    propertiesMap,
    calculatePreviousPeriod: true,
    teamsMap
  });

  const value = extractGroupAggregationValue(
    settings,
    result.projectReportsConnection.aggregates,
    propertiesMap
  ) as number;
  const prevPeriodValue = extractGroupAggregationValue(
    settings,
    prevPeriodResult.projectReportsConnection.aggregates,
    propertiesMap
  ) as number;
  const daysInPeriod = Math.round(
    calcDiffInDaysForDateRangeOption(settings.dateRangeType, settings.dateRangeStart, settings.dateRangeEnd)
  );

  const valueAsNumber = value !== null ? parseFloat(value) : null;
  const prevValueAsNumber = prevPeriodValue !== null ? parseFloat(prevPeriodValue) : null;

  let diffInPercent;

  if (valueAsNumber === null || prevValueAsNumber === null) {
    diffInPercent = null;
  } else if (prevValueAsNumber === 0) {
    diffInPercent = null;
  } else {
    diffInPercent = ((valueAsNumber - prevValueAsNumber) / prevValueAsNumber) * 100;
  }

  return {
    isEmpty: value === null,
    value: valueAsNumber,
    daysInPeriod,
    diffInPercent
  };
};
