import React, { useCallback, useMemo } from 'react';
import { useConnectIntegrations, useDeleteConnectIntegration } from '@hooks/workspace/connectIntegration';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { useAppSelector } from '@hooks/store';
import { ConnectIntegrationType } from '@features/Platform/Integrations';
import { selectWorkspaceId } from '@state/selectors';
import { CheckIcon } from '@kit/ui/icons/Check';
import { Badge, BadgeSize } from '@kit/ui/Badge';
import { navigate } from 'gatsby';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { ContextMenu } from '@kit/components/ContextMenu';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { Box } from '@material-ui/core';
import { ConnectIntegration } from '@generated/types/graphql';
import { ConnectIntegrationName } from '@features/Platform/Integrations/const';
import { CreateOpenSolarIntegration } from '@features/Platform/Integrations/OpenSolar';
import { CreateAuroraIntegration } from './Aurora/CreateAuroraIntegration';
import AuroraIcon from '../../../assets/svg/aurora.svg';
import {
  Container,
  Description,
  H2,
  Header,
  ProposalTool,
  ProposalToolDescription,
  ProposalToolsContainer,
  Sections,
  Title
} from './styled';
import OpenSolarIcon from '../../../assets/svg/opensolar.svg';

export const Integrations = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { data: integrations } = useConnectIntegrations({ companyId });
  const { openModal } = useModal();

  const handleConnectAurora = useCallback(async () => {
    const connectIntegrationId = await openModal<void | number>(
      ({ onClose }) => <CreateAuroraIntegration onClose={onClose} />,
      {
        title: 'Connect Aurora Solar'
      }
    );
    if (connectIntegrationId) {
      navigate(`./aurora/${connectIntegrationId}`);
    }
  }, [openModal]);

  const handleConnectOpenSolar = useCallback(async () => {
    const connectIntegrationId = await openModal<void | number>(
      ({ onClose }) => <CreateOpenSolarIntegration onClose={onClose} />,
      {
        title: 'Connect OpenSolar'
      }
    );
    if (connectIntegrationId) {
      navigate(`./opensolar/${connectIntegrationId}`);
    }
  }, [openModal]);

  const { mutateAsync: deleteIntegration } = useDeleteConnectIntegration();
  const confirmDelete = useConfirmDeleteModal();

  const makeIntegrationMenu = useCallback(
    (integration: ConnectIntegration) => [
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (
            await confirmDelete(
              `Are you sure you want to disconnect ${ConnectIntegrationName[integration.type as ConnectIntegrationType]} integration?`
            )
          ) {
            await deleteIntegration(integration.id);
          }
        }
      }
    ],
    [deleteIntegration, confirmDelete]
  );

  const aurora = useMemo(
    () => integrations.find((integration) => integration.type === ConnectIntegrationType.AURORA_SOLAR),
    [integrations]
  );

  const opensolar = useMemo(
    () => integrations.find((integration) => integration.type === ConnectIntegrationType.OPEN_SOLAR),
    [integrations]
  );

  return (
    <Container>
      <Header>
        <Title>Integrations</Title>
        <Description>
          Connect and manage integrations, enabling efficient data synchronization and streamlined workflows across
          systems.
        </Description>
      </Header>

      <Sections style={{ marginTop: '25px', marginBottom: '50px', gap: '0px' }}>
        <H2>Proposal Tools</H2>
        <ProposalToolsContainer>
          <ProposalTool onClick={() => aurora && navigate(`./aurora/${aurora.id}`)} installed={!!aurora}>
            <img src={AuroraIcon} width={182} height={40} alt="Aurora" />
            <ProposalToolDescription>
              Get seamless access to solar design data from Aurora, enabling automated data transfers and enhanced
              project management within your platform.
            </ProposalToolDescription>
            <div>
              {aurora ? (
                <Box display="flex" gridGap={8}>
                  <Badge size={BadgeSize.Large} color="#009688" bgColor="#cdf3df" style={{ cursor: 'pointer' }}>
                    <CheckIcon size={12} />
                    Connected
                  </Badge>

                  <div onClick={(e) => e.stopPropagation()}>
                    <ContextMenu items={makeIntegrationMenu(aurora)}>
                      <MoreVerticalIcon size="24px" color="#9C9CAA" />
                    </ContextMenu>
                  </div>
                </Box>
              ) : (
                <Button variant={ButtonVariant.Primary} size={ButtonSize.Large} onClick={handleConnectAurora}>
                  Connect
                </Button>
              )}
            </div>
          </ProposalTool>

          <ProposalTool onClick={() => opensolar && navigate(`./opensolar/${opensolar.id}`)} installed={!!opensolar}>
            <img src={OpenSolarIcon} width={182} height={40} alt="OpenSolar" />
            <ProposalToolDescription>
              Get seamless access to solar design data from OpenSolar, enabling automated data transfers and enhanced
              project management within your platform.
            </ProposalToolDescription>
            <div>
              {opensolar ? (
                <Box display="flex" gridGap={8}>
                  <Badge size={BadgeSize.Large} color="#009688" bgColor="#cdf3df" style={{ cursor: 'pointer' }}>
                    <CheckIcon size={12} />
                    Connected
                  </Badge>

                  <div onClick={(e) => e.stopPropagation()}>
                    <ContextMenu items={makeIntegrationMenu(opensolar)}>
                      <MoreVerticalIcon size="24px" color="#9C9CAA" />
                    </ContextMenu>
                  </div>
                </Box>
              ) : (
                <Button variant={ButtonVariant.Primary} size={ButtonSize.Large} onClick={handleConnectOpenSolar}>
                  Connect
                </Button>
              )}
            </div>
          </ProposalTool>
        </ProposalToolsContainer>
      </Sections>
    </Container>
  );
};
