import styled from 'styled-components';

export const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
`;

export const SubDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
  margin-bottom: 16px;
`;

export const Label = styled.label`
  display: block;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #828d9a;
`;
