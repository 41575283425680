import { ConnectIntegration } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';

export const useConnectIntegration = (args: { id: number }) =>
  useQuery<ConnectIntegration>(
    [ReactQueryKey.ConnectIntegration, 'useConnectIntegration', args],
    async () => {
      try {
        return (
          await postGraphql<{ connectIntegration: ConnectIntegration }>(
            gql`
              query CONNECT_INTEGRATION_QUERY($id: Int!) {
                connectIntegration(id: $id) {
                  id
                  blueprint {
                    id
                    name
                  }
                  connectColumnMappingsByIntegrationId(orderBy: [COLUMN_ID_ASC]) {
                    column {
                      id
                      name
                    }
                    standard
                    valuePath
                  }
                  matchFallbackProjectColumn {
                    id
                    name
                  }
                  matchFoundStrategy
                  matchMainProjectColumn {
                    id
                    name
                  }
                  type
                }
              }
            `,
            args
          )
        ).connectIntegration;
      } catch (e) {
        throw apiErrorHandler('Error fetching integration', e);
      }
    },
    {
      initialData: null
    }
  );
