import { assign, has, identity, isEmpty, isObject, isString, isUndefined, replace, sortBy } from 'lodash/fp';

import {
  AggregateColumn,
  Identified,
  ProjectAdapted,
  Property,
  PropertyType,
  PropertyValueDTO,
  RecordType,
  VirtualPropertyType
} from '@types';
import UserPreferencesService, { queryByRecord } from '@services/UserPreferences';
import { File, Project, ProjectPropertyValue } from '@generated/types/graphql';
import moment from 'moment';
import { get } from 'lodash';

/** Mirrors BE's AdditionalDTO */
export type PropertyValue =
  | string // TEXT, BOOLEAN, DATE, single DROPDOWN
  | number // NUMERIC
  | boolean // BOOLEAN (actually tracked as text value)
  | string[] // multiple DROPDOWN
  | Date // DATE (actually string as it will be converted to JSON anyway)
  | { id: number } // PERSON
  | File[]; // FILE

const ARRAY_PROPERTIES = [-3];

export const isEmptyValue = (value: any) =>
  ((isObject(value) || isString(value)) && isEmpty(value)) || value === null || isUndefined(value);

const makeComparator =
  <T = Property>(type: PropertyType) =>
  (property: any): property is T => {
    if (isEmpty(property)) {
      return false;
    }

    return (property as Property).type === type;
  };

export const isDefault = (property: Property) => property && property.id <= 0;
export const isAdditional = (property: Property) => property && !!property.isAdditional;
export const isDropdown = makeComparator(PropertyType.Dropdown);
export const isPerson = makeComparator<{ firstName: string; lastName: string; id: number }>(PropertyType.Person);
export const isText = makeComparator(PropertyType.Text);
export const isDate = (property: Property) =>
  !isEmpty(property) && [PropertyType.Date, PropertyType.DateTime].includes(property.type);
export const isTimeRangeDays = (property: Property) => property?.type === PropertyType.TimeRangeDays;
export const isDateTime = makeComparator(PropertyType.DateTime);
export const isNumeric = makeComparator(PropertyType.Numeric);
export const isFiles = makeComparator(PropertyType.File);
export const isButtonOrLink = makeComparator(PropertyType.Link);
export const isAddress = (property: Property) => property?.mappedName === 'address';
export const isTrades = (property: Property) => property?.mappedName === 'trades';
export const isDealValue = (property: Property) => property?.mappedName === 'dealValue';
export const isProjectValue = (property: Property) => property?.mappedName === 'projectValue';
export const isStage = (property: Property) => property?.mappedName === 'stageId' || isTimelineStage(property);
export const isTimelineStage = (property: Property) => property?.mappedName === 'timelineStageId';
export const isTimelineStatus = (property: Property) => property?.mappedName === 'timelineStatus';
export const isConfidence = (property: Property) => property?.mappedName === 'confidence';
export const isOwner = (property: Property) => property?.mappedName === 'ownerId';
export const isDescription = (property: Property) => property?.mappedName === 'description';
export const isStatus = (property: Property) => property?.mappedName === 'status';
export const isRequestStatus = (property: Property) => property?.mappedName === 'requestStatus';
export const isClientStatus = (property: Property) => property?.mappedName === 'accountStatus';
export const isReferredBy = (property: Property) => property?.mappedName === 'referrerContactId';
export const isCallDisposition = (property: Property) =>
  property?.mappedName === 'lastOutboundCallDisposition' || property?.mappedName === 'lastInboundCallDisposition';
export const isPrimaryPhone = (property: Property) => property?.mappedName === 'primaryPhone';
export const isPrimaryEmail = (property: Property) => property?.mappedName === 'primaryEmail';
export const isAhj = (property: Property) => property?.mappedName === 'jurisdictionId';
export const isMoneyProperty = (property: Property) =>
  ['totalCost', 'totalPrice', 'revenue'].includes(property?.mappedName);
export const isConnectProposalTool = (property: Property) => property?.keyName === 'proposal_tool_name';

export const isId = (property: Property) => property?.mappedName === 'id';
export const isUid = (property: Property) => property?.mappedName === 'uid';
export const isTaskStatus = (property: Property) =>
  property?.virtual && property?.virtualType === VirtualPropertyType.taskStatus;
export const isTaskWithTitle = (property: Identified) => property?.id === AggregateColumn.TASK_WITH_TITLE;

export const isArray = (property: Property) => ARRAY_PROPERTIES.includes(property?.id as number);
export const isSlaStatus = (property: Property) => property?.mappedName === 'slaStatus';

const multipleProps: PropertyType[] = [PropertyType.Dropdown];

export const isStrictMultiple = (property: Property): boolean | undefined =>
  multipleProps.includes(property.type) ? !!property.multiple : undefined;

export const isMultiple = (property: Property): boolean => !!isStrictMultiple(property);

/**
 * Indicates whether property value could be updated directly by user, e.g. in record form or by automation.
 */
export const isEditable = (p: Property): boolean =>
  (!p.readonly && !p.virtual) || (p.mappedName === 'address' && p.id === -3);

/**
 * @deprecated Must be rewritten to start making sense. Prefer <PropertyValue>.
 */
export const getPropertyValueById = (project: ProjectAdapted, property: Property, sanitize: boolean = true) => {
  if (isReferredBy(property)) {
    return project.projectDetail.referrerContact;
  }
  if (property?.displayValuePath) {
    return get(project?.projectDetail, property.displayValuePath);
  }

  const isPropAdditional = property.isAdditional || has(property.id, project.projectDetail.additional);
  const sanitizeFn =
    String(property.mappedName).toLowerCase() === 'description' && sanitize ? replace(/<[^>]+>/g, '') : identity;

  const value = isPropAdditional
    ? project.projectDetail.additional[property.id]
    : sanitizeFn(
        project.projectDetail[
          (property.objectName || property.mappedName || property.name) as keyof ProjectAdapted['projectDetail']
        ] as string
      );

  if (isPerson(property) && value) {
    return assign(
      {
        toString: () => `${value.firstName || ''} ${value.lastName || ''}`,
        companyId: project.projectDetail.companyId
      },
      value
    );
  }

  return value;
};

export const defaultOrdersByRecordType = {
  [RecordType.ACCOUNT]: {
    [-1]: 0, // Title
    [-3]: 1, // Address
    [-29]: 2, // Account type
    [-31]: 3, // Account owner
    [-2]: 4, // Description
    [-33]: 5, // Primary phone
    [-32]: 6 // Primary email
  },

  [RecordType.PROJECT]: {
    // -999 added so this ones are always at the beginning
    [-1]: -9997, // Title
    [-34]: -9996, // Trades
    [-3]: -9995, // Address
    [-36]: -9994, // Address city
    [-37]: -9993, // Address state
    [-38]: -9992, // Address stree
    [-39]: -9991, // Address zip

    // 999 added so this ones are always at the end
    [-33]: 9997, // Primary phone
    [-32]: 9998, // Primary email
    [-43]: 9999, // Project value
    [-44]: 99910, // Project size

    [-40]: 99911, // Sales Rep
    [-41]: 99912, // Project Manager
    [-31]: 99913, // Owner

    [-46]: 99914, // Status
    [-2]: 99915 // Description
  },
  [RecordType.DEAL]: {
    [-1]: 0, // Title
    [-3]: 1, // Address
    [-31]: 2, // Deal owner
    [-24]: 3, // Workflow
    [-27]: 4, // Deal value
    [-30]: 5, // Confidence
    [-2]: 6, // Description
    [-33]: 7, // Primary phone
    [-32]: 8 // Primary email
  }
};

// exclude address parts and stage
const EXCLUDE_PROPERTIES = [-36, -37, -38, -39, -25];

export const sortAndFilterStandardProperties = (properties: Property[]) => {
  const order = defaultOrdersByRecordType[RecordType.PROJECT];

  return properties
    .filter((property) => !EXCLUDE_PROPERTIES.includes(property.id))
    .sort((a, b) => (order[a.id] ?? 0) - (order[b.id] ?? 0));
};

export const saveOrder = (
  companyId: number,
  recordType: RecordType,
  properties: { id: number | string; position: number }[]
) => {
  const order = sortBy('position', properties).reduce((acc, { id }, index) => ({ ...acc, [id]: index }), {});

  UserPreferencesService.propertiesOrder.set(queryByRecord({ companyId, recordType }), order);
};

export const getStoredOrder = (companyId: number, recordType: RecordType) => {
  return (
    UserPreferencesService.propertiesOrder.get(queryByRecord({ companyId, recordType })) ||
    defaultOrdersByRecordType[recordType]
  );
};

export const applyOrder = (companyId: number, recordType: RecordType, properties: Property[]) => {
  const order = getStoredOrder(companyId, recordType);

  return properties.sort((a, b) => {
    if (recordType === RecordType.PROJECT && order.isDefaultOrder) {
      return (order[a.id] ?? 0) - (order[b.id] ?? 0);
    }

    if (a.id in order && b.id in order) {
      return order[a.id] - order[b.id];
    }

    if (a.id in order) {
      return -1;
    }

    if (b.id in order) {
      return 1;
    }

    return +a.id - +b.id;
  });
};

export const getAdditionalPropertyValue = (propertyValue: ProjectPropertyValue): PropertyValue => {
  switch (propertyValue.column!.type) {
    case PropertyType.Date:
    case PropertyType.DateTime:
      if (propertyValue.dateValue) {
        const date: PropertyValue = moment.utc(propertyValue.dateValue).toDate();
        date.allDay = propertyValue.dateValueAllDay;

        return date;
      }

      return propertyValue.dateValue!;
    case PropertyType.Text:
    case PropertyType.Link:
      return propertyValue.textValue!;
    case PropertyType.Numeric:
      return propertyValue.numericValue!;
    case PropertyType.TimeRangeDays:
      return propertyValue.timeRangeDaysValue;
    case PropertyType.Person:
      return propertyValue.userByWorkerValue!;
    case PropertyType.Dropdown:
      return propertyValue.column!.multiple ? propertyValue.dropdownValue! : propertyValue.dropdownValue?.[0];
    case PropertyType.File:
      return propertyValue.files!;
    default:
      throw new Error(`Unrecognized property type ${propertyValue.column!.type}`);
  }
};

/**
 * Convert new 'projectPropertiesValues' table holding prop values into old JSON 'additional' field
 */
export const propertiesValuesToAdditional = (values?: ProjectPropertyValue[]): { [columnId: number]: PropertyValue } =>
  (values || []).reduce(
    (acc, value) => ({
      ...acc,
      [value.columnId]: getAdditionalPropertyValue(value)
    }),
    {}
  );

export const getDefaultPropertyValue = (property: Property, value: PropertyValue) => {
  switch (property.type) {
    case PropertyType.Date:
    case PropertyType.DateTime:
    case PropertyType.Dropdown:
    case PropertyType.Text:
    case PropertyType.Link:
    case PropertyType.File:
      return value;
    case PropertyType.Person:
    case PropertyType.Numeric:
      return +value;
    default:
      throw new Error(`Unrecognized property type ${property.type}`);
  }
};

/**
 * Returns value of a custom property which is acceptable to BE's AdditionalDTO
 */
export const getCustomDTOValue = (type: PropertyType, value: PropertyValue | null): PropertyValueDTO => {
  if (!value) {
    return value;
  }

  switch (type) {
    case PropertyType.File:
      return (value as File[]).map(({ id }) => id);
    case PropertyType.Person:
      return { id: (value as { id: number }).id };
    default:
      return value as PropertyValueDTO;
  }
};

export const getPrimaryEmailPhone = (record: Project) => ({
  primaryEmail: record?.projectContacts?.[0]?.emails?.[0],
  primaryPhone: record?.projectContacts?.[0]?.phones?.[0]
});

const CUSTOM_PROPERTY_TEMPLATE_PADDING = -1000;

export const isTaskTemplateStandardProperty = (id: number) => id > CUSTOM_PROPERTY_TEMPLATE_PADDING;
export const convertFromTemplateMemberIdToPropertyId = (id: number) =>
  CUSTOM_PROPERTY_TEMPLATE_PADDING - id > 0 ? CUSTOM_PROPERTY_TEMPLATE_PADDING - id : id;

export const convertFromPropertyIdToTemplateMemberId = (id: number) =>
  id < 0 ? id : -id + CUSTOM_PROPERTY_TEMPLATE_PADDING;

// Helper function to append tracking params to url (https://linear.app/coperniq/issue/ENG-1446)
export const appendTrackingParamsToUrl = (url: string, recordId?: number, recordType?: RecordType) => {
  try {
    const urlObj = new URL(url);
    // Always append source
    urlObj.searchParams.set('source', 'coperniq');

    // If no recordId or recordType, return the url with source only
    if (!recordId || !recordType) {
      return urlObj.toString();
    }

    // Append client_id, project_id, or request_id to the url depending on the recordType
    switch (recordType) {
      case RecordType.ACCOUNT:
        urlObj.searchParams.set('client_id', recordId.toString());
        break;
      case RecordType.PROJECT:
        urlObj.searchParams.set('project_id', recordId.toString());
        break;
      case RecordType.DEAL:
        urlObj.searchParams.set('request_id', recordId.toString());
        break;
    }

    return urlObj.toString();
  } catch (error) {
    console.error(`Error appending tracking params to URL: ${url}`, error);
    return url;
  }
};
