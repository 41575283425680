import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';
import { useMemo } from 'react';
import { MODULE_OPTIONS } from '../constants';
import { AnalyticsModule } from '../types';

export const useModuleOptions = () => {
  const companySettings = useAppSelector(selectCompanySettings);

  return useMemo(
    () =>
      MODULE_OPTIONS.filter((option) => {
        if (option.value === AnalyticsModule.REQUESTS) {
          return companySettings?.features?.requests;
        }

        return true;
      }),
    [companySettings?.features?.requests]
  );
};
