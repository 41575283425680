import React, { useMemo, useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { FormControlLabel, LinearProgress } from '@material-ui/core';
import { RadioGroup } from 'formik-material-ui';

import { Modal, CancelButton, SubmitButton } from '@components/common/ModalMui';
import { useRoutes, useCompanyProperties, useBlueprints, useAppSelector } from '@hooks';
import { StyledRadio } from '@components/common/Radio';
import { DateField, BoxRow } from '@components/common';
import { isEmpty } from '@utils';
import { RecordType, PaginationResult, BlueprintDTO } from '@types';
import { startCase } from 'lodash';
import { SelectFormik } from '@common/ui';

import { useRecordsMutations, ExportMethod } from '@hooks/useRecords';
import { useSmartViewList } from '@hooks/smartViews/useSmartViewList';
import { AnalyticsWidgetDateRangeType } from '@features/Analytics/types';
import { DateTime } from 'luxon';
import { selectCompanySettings } from '@state/selectors';
import { schema } from './validation';
import { Text, Label, RadioLabel, DatePickersWrapper, Wrapper, Emoji, Name } from './styled';

const startDateOptions = [
  {
    label: 'Last week',
    value: AnalyticsWidgetDateRangeType.LAST_WEEK
  },
  {
    label: 'Last 30 days',
    value: AnalyticsWidgetDateRangeType.LAST_30_DAYS
  },
  {
    label: 'Last 90 days',
    value: AnalyticsWidgetDateRangeType.LAST_90_DAYS
  }
];

const RECORD_TYPES: { id: string; name: string }[] = [
  {
    id: RecordType.PROJECT,
    name: 'Projects'
  },
  {
    id: RecordType.ACCOUNT,
    name: 'Clients'
  },

  {
    id: RecordType.DEAL,
    name: 'Requests'
  }
];

interface ExportModalProps {
  open: boolean;
  onClose: () => unknown;
}

export const ExportModal: React.FC<ExportModalProps> = (props) => {
  const { open, onClose } = props;
  const { companyId } = useRoutes();
  const companySettings = useAppSelector(selectCompanySettings);

  const isRequestsEnabled = companySettings?.features?.requests;

  const [recordType, setRecordType] = useState(RecordType.PROJECT);
  const { data: smartViews = [] } = useSmartViewList();

  const recordTypeOptions = useMemo(() => {
    return RECORD_TYPES.filter(({ id }) => {
      if (id === RecordType.DEAL) {
        return isRequestsEnabled;
      }

      return true;
    });
  }, [isRequestsEnabled]);

  const filteredSmartViews = useMemo(() => {
    return smartViews.filter(({ type }) => type === recordType);
  }, [smartViews, recordType]);

  const { filterColumns: properties } = useCompanyProperties({ recordType });
  const {
    fetchAll: { data: blueprints = {} as PaginationResult<BlueprintDTO> }
  } = useBlueprints();
  const { exportCsv } = useRecordsMutations(companyId, recordType);

  const renderSmartViewName = (viewId: number) => {
    const view = smartViews.find(({ id }) => id === viewId)!;

    return (
      <>
        <Emoji role="img">{view?.emoji}</Emoji>
        <Name>{view?.name}</Name>
      </>
    );
  };

  return (
    <Formik
      initialValues={{
        $startDate: startDateOptions[0].value,
        customStartDate: DateTime.now().minus({ days: 7 }).toJSDate(),
        customEndDate: new Date(),
        recordType: RecordType.PROJECT,
        exportMethod: ExportMethod.DATE_RANGE,
        smartViewId: smartViews[0]?.id
      }}
      validationSchema={schema}
      onSubmit={async ($values) => {
        const values = {
          projectType: $values.recordType,
          dateRangeType: $values.$startDate,
          customStartDate: $values.customStartDate,
          customEndDate: $values.customEndDate
        };

        const view = smartViews.find(({ id }) => id === $values.smartViewId);

        await exportCsv.mutateAsync({
          ...values,
          blueprints: blueprints?.results ?? [],
          exportMethod: $values.exportMethod,
          properties,
          view
        });

        onClose();
      }}
    >
      {({ handleSubmit, values, errors, isSubmitting }) => (
        <Modal
          open={open}
          title="Export records"
          onClose={onClose}
          footerButtons={[
            <CancelButton onClick={onClose} key="cancel" />,
            <SubmitButton
              key="submit"
              onClick={handleSubmit}
              text="Export"
              disabled={!isEmpty(errors) || isSubmitting}
              data-analyticsid="submitExportRecords"
            />
          ]}
        >
          <Wrapper disabled={isSubmitting}>
            <Text>
              You are about to export records as a .csv file. The download may take a couple moments to start!
            </Text>
            <BoxRow half>
              <div>
                <Label>Record type</Label>
                <SelectFormik
                  name="recordType"
                  options={recordTypeOptions}
                  noBorder
                  disabled={isSubmitting}
                  onChange={(event) => setRecordType(event.target.value)}
                />
              </div>
              <div>
                <Label>Export method</Label>
                <SelectFormik
                  name="exportMethod"
                  options={[ExportMethod.DATE_RANGE, ExportMethod.SMART_VIEW].map((type) => ({
                    id: type,
                    name: startCase(type)
                  }))}
                  noBorder
                  disabled={isSubmitting}
                />
              </div>
            </BoxRow>
            {values.exportMethod === ExportMethod.DATE_RANGE ? (
              <>
                <Form>
                  <Label>Date range</Label>
                  <Field component={RadioGroup} name="$startDate">
                    {startDateOptions.map(({ label, value }) => (
                      <FormControlLabel
                        key={label + value}
                        value={value}
                        control={<StyledRadio />}
                        label={<RadioLabel>{label}</RadioLabel>}
                        disabled={isSubmitting}
                      />
                    ))}
                    <FormControlLabel
                      key="custom_range"
                      value={AnalyticsWidgetDateRangeType.CUSTOM}
                      control={<StyledRadio />}
                      label={<RadioLabel>Custom</RadioLabel>}
                      disabled={isSubmitting}
                    />
                  </Field>
                </Form>
                <Form>
                  {values.$startDate === 'custom' ? (
                    <DatePickersWrapper>
                      <BoxRow half>
                        <DateField
                          name="customStartDate"
                          label="Start Date"
                          dateFormat="MM/d/yyyy"
                          showTimeSelect={false}
                          selectsStart
                          disabled={isSubmitting}
                        />
                        <DateField
                          name="customEndDate"
                          label="End Date"
                          dateFormat="MM/d/yyyy"
                          showTimeSelect={false}
                          selectsEnd
                          disabled={isSubmitting}
                        />
                      </BoxRow>
                    </DatePickersWrapper>
                  ) : null}
                </Form>
              </>
            ) : (
              <div>
                <Label>Smart view</Label>
                <SelectFormik
                  name="smartViewId"
                  options={filteredSmartViews}
                  renderOption={renderSmartViewName}
                  renderValue={renderSmartViewName}
                  disabled={isSubmitting}
                />
              </div>
            )}
          </Wrapper>
          {isSubmitting && (
            <Form>
              <Label>Preparing the CSV...</Label>
              <LinearProgress />
            </Form>
          )}
        </Modal>
      )}
    </Formik>
  );
};
