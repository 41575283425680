import React, { useMemo } from 'react';
import { Box } from '@material-ui/core';
import { Select } from '@kit/ui/Select';
import { Label, SubDescription, SubTitle } from '../styled';
import { ConnectIntegration } from '@generated/types/graphql';
import { useCompanyProperties } from '@hooks';
import { ImportMatchFoundStrategy, RecordType } from '@types';
import { useUpdateConnectIntegration } from '@hooks/workspace/connectIntegration';

type Props = {
  integration: ConnectIntegration;
};

export const MatchingStrategySelect = ({integration}: Props) => {
  const { columns = [] } = useCompanyProperties({ recordType: RecordType.PROJECT });

  const { mutateAsync: updateIntegration } = useUpdateConnectIntegration();

  const fallbackMatchOptions = useMemo(
    () => columns.filter((column) => ['title', 'primaryEmail', 'primaryPhone', 'address'].includes(column.mappedName)),
    [columns]
  );

  const handleUpdateFallbackMatchColumn = async (columnId: number) => {
    await updateIntegration({ integrationId: integration.id, dto: { matchFallbackProjectColumnId: columnId } });
  };

  const handleUpdateMatchFoundStrategy = async (strategy: ImportMatchFoundStrategy) => {
    await updateIntegration({ integrationId: integration.id, dto: { matchFoundStrategy: strategy } });
  };

  return (
    <>
      <SubTitle>Matching strategy</SubTitle>
      <SubDescription>
        To avoid duplicates, we can check the Clients in Coperniq and compare them. If there’s a match then missing
        fields can be added or everything can be updated.
      </SubDescription>
      <Box display="flex" justifyContent="space-between" gridGap={40}>
        <div style={{ width: '100%' }}>
          <Label>Fallback matching key</Label>
          <Select<{ id: number; name: string }>
            options={fallbackMatchOptions}
            value={integration.matchFallbackProjectColumn}
            isClearable={false}
            getOptionLabel={(option) => option.name}
            onChange={(_, value) => handleUpdateFallbackMatchColumn(value.id)}
          />
        </div>

        <div style={{ width: '100%' }}>
          <Label>If a match is found</Label>
          <Select
            options={Object.values(ImportMatchFoundStrategy)}
            value={integration.matchFoundStrategy}
            isClearable={false}
            getOptionLabel={(value) => ImportMatchFoundStrategyReverse[value]}
            onChange={(_, value) => handleUpdateMatchFoundStrategy(value)}
          />
        </div>
      </Box>
    </>
  );
};

const ImportMatchFoundStrategyReverse = Object.entries(ImportMatchFoundStrategy).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [value]: key
  }),
  {} as { [value in ImportMatchFoundStrategy]: string }
);
