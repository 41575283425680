import { capitalize } from 'lodash';

export const valuePathToName = (valuePath: string) =>
  valuePath
    .split(',')
    .map((part) =>
      part
        .split('.')
        .map((field) =>
          field
            .split('_')
            .map((word) => capitalize(word.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1')))
            .join(' ')
        )
        .join(' > ')
    )
    .join('<br/>');
