import { ReactQueryKey } from '@enums';
import { FeedInbox, FeedInboxFilter, FeedInboxesConnection } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { DeepPartial } from 'redux';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector, useAuth } from '..';

const fetchNotification = async ({ companyId, id }: { companyId: number; id: number }) => {
  const feedFilter: DeepPartial<FeedInboxFilter> = {
    id: { equalTo: id },
    feed: {
      companyId: { equalTo: companyId }
    }
  };

  try {
    const {
      feedInboxesConnection: { nodes }
    } = await postGraphql<{ feedInboxesConnection: FeedInboxesConnection }>(
      gql`
        query NOTIFICATION_BY_ID_QUERY($filter: FeedInboxFilter) {
          feedInboxesConnection(filter: $filter) {
            nodes {
              id
              entityType
              eventType
              createdAt
              read

              feed {
                id
                event
                eventType
                virtualCreatedAt
                parent {
                  id
                  virtualCreatedAt
                }
                payload

                task {
                  uid
                  isField
                }

                reminder {
                  id
                  type
                }

                createdByContact {
                  id
                  name
                  portalStatus
                }

                createdByUser {
                  id
                  firstName
                  lastName
                  avatarUrl
                  phone
                }

                project {
                  id
                  uid
                  title
                  type
                  source
                }

                emailMessage {
                  id
                  from
                  to
                  subject
                  body
                  bodyCleaned
                }

                relatedComment {
                  id
                  comment
                }

                smsActivity {
                  text
                  fromPhone
                  fromUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                    phone
                  }
                  fromContact {
                    id
                    name
                    phones
                    emails
                    status
                  }
                  fromCompanyPhone {
                    id
                    alias
                    phone
                  }
                  fromJurisdiction {
                    id
                    name
                    phones
                    emails
                    address
                  }
                  toUser {
                    id
                    firstName
                    lastName
                    avatarUrl
                    phone
                  }
                  toContact {
                    id
                    name
                    phones
                    emails
                    status
                  }
                  toCompanyPhone {
                    id
                    alias
                    phone
                  }
                  toJurisdiction {
                    id
                    name
                    phones
                    emails
                    address
                  }
                  text
                  status
                  isIncoming
                }
              }
            }
            totalCount
          }
        }
      `,
      { filter: feedFilter },
      undefined,
      { useReplica: true }
    );

    return nodes[0];
  } catch (e) {
    throw apiErrorHandler(`Error fetching notification`, e);
  }
};

export const useNotification = (id?: number) => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { user } = useAuth();

  return useQuery<FeedInbox>(
    [ReactQueryKey.InboxNotificationsById, id, companyId],
    () => fetchNotification({ companyId, id }),
    {
      enabled: user.userId !== 0 && id !== undefined
    }
  );
};
