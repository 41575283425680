import { useRecordDetail } from '@hooks/useRecordDetail';
import React, { useCallback } from 'react';
import { CopyButton } from '@common/ui';
import { capitalize } from 'lodash';
import { ExternalLink, Home, MapPin } from 'react-feather';
import { getGeoLink } from '@utils/getGeoLink';
import moment from 'moment';
import { CustomProperties } from '@features/ClientPortfolio/Client/Overview/Properties/CustomProperties';
import { useModal } from '@common/PromiseModal';
import { ContactForm } from '@features/ClientPortfolio/Client/Overview/Stakeholders/ContactForm';
import { UserAvatar } from '@kit/components/UserAvatar';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { formatMoney } from '@utils/utils';
import { Status } from '@features/ProjectPortfolio/components/Status';
import { getColorAndLabel } from '@features/ProjectPortfolio/components/Status/Status';
import { Badge } from '@kit/ui/Badge';
import { BadgeSize } from '@kit/ui/Badge/types';
import { AccountType } from '@types';
import { BuildingIcon } from '@kit/ui/icons/Building';
import { hasEntityAccessNew } from '@utils/roles';
import { colors } from '@styles';
import { ConnectIntegrationType } from '@features/Platform/Integrations';
import { Box } from '@material-ui/core';
import { ConnectIntegrationBrand } from '@features/Platform/Integrations/Common/ConnectIntegrationBrand/ConnectIntegrationBrand';
import {
  AddressAndIcon,
  Chips,
  Container,
  Copyable,
  EditableChipPlaceholder,
  EditableWrapper,
  InfoAddress,
  InfoBlock,
  PrimaryContactBlock,
  ProjectContact,
  ProjectContactInfo,
  ProjectContactLabel,
  ProjectContacts,
  StaticPropertyValue,
  StatusContainer
} from './styled';
import { Description } from './EditableFields';
import { EditableField } from './EditableField';

interface Props {
  projectId: number;
}

export const Properties = ({ projectId }: Props) => {
  const { data: record, isLoading } = useRecordDetail(projectId);
  const { openModal } = useModal();

  const canEdit = record && hasEntityAccessNew(record, 'edit');

  const primaryContact = record?.projectContacts[0];
  const connectSource = record?.connectSourcesByProjectId?.[0];

  const handleEditPrimaryContact = useCallback(() => {
    openModal<void>(
      ({ onClose }) => <ContactForm recordId={projectId} initialValues={primaryContact} onClose={onClose} />,
      { title: 'Edit Contact' }
    );
  }, [projectId, primaryContact, openModal]);

  if (isLoading || !record.id) {
    return null; // TODO
  }

  const primaryEmail = primaryContact?.emails?.[0];
  const primaryPhone = primaryContact?.phones?.[0];

  return (
    <Container>
      <InfoBlock>
        <Copyable>
          <AddressAndIcon>
            <InfoAddress href={getGeoLink(record.geoLocation, record.address)} target="_blank" rel="noreferrer">
              <MapPin size={13} />
              <div>{record.address?.[0] ?? ''}</div>
            </InfoAddress>
          </AddressAndIcon>

          <CopyButton value={record.address?.[0]} />
        </Copyable>

        <Chips>
          {record.account && (
            <Badge bgColor="#E4E8EC" color="#1D1D35" size={BadgeSize.Large}>
              {record.account.accountType === AccountType.RESIDENTIAL ? (
                <Home color="#9C9CAA" size="16px" />
              ) : (
                <BuildingIcon color="#9C9CAA" size="16px" />
              )}
              {capitalize(record.account.accountType)}
            </Badge>
          )}

          {record.jurisdiction && (
            <Badge bgColor="#E4E8EC" color="#1D1D35" size={BadgeSize.Large}>
              AHJ: {record.jurisdiction.name}
            </Badge>
          )}

          <EditableField project={record} propertyName="trades">
            <Badge bgColor={canEdit ? '#fff' : '#E4E8EC'} color="#1D1D35" size={BadgeSize.Large}>
              {!record.trades?.length && <EditableChipPlaceholder>Select trades</EditableChipPlaceholder>}
              {record.trades?.length > 0 && <div>Trades: {record.trades?.join(', ')}</div>}
            </Badge>
          </EditableField>

          <EditableField project={record} propertyName="projectSize">
            <Badge bgColor={canEdit ? '#fff' : '#E4E8EC'} color="#1D1D35" size={BadgeSize.Large}>
              {record.projectSize} kW
            </Badge>
          </EditableField>

          <EditableField project={record} propertyName="projectValue">
            <Badge bgColor={canEdit ? '#fff' : '#E4E8EC'} color="#1D1D35" size={BadgeSize.Large}>
              <span className="infoChipLabel">
                <span className="infoChipCurrencySymbol">$&nbsp;</span>
              </span>
              {formatMoney(record.projectValue).replace('$', '')}
            </Badge>
          </EditableField>

          {connectSource && (
            <Badge bgColor={colors.white} color={colors.black} size={BadgeSize.Large}>
              <Box display="flex" alignItems="center" gridGap={8}>
                <ConnectIntegrationBrand type={connectSource.integration.type as ConnectIntegrationType} />
                <a href={connectSource.info.url} target="_blank" rel="noreferrer">
                  <ExternalLink size={16} color={colors.blue} />
                </a>
              </Box>
            </Badge>
          )}
        </Chips>

        {record.projectContacts.length > 0 && (
          <PrimaryContactBlock>
            <div>
              <ProjectContactLabel>Primary contact</ProjectContactLabel>
            </div>

            <ProjectContacts>
              <EditableWrapper isEditable={canEdit} onClick={canEdit ? handleEditPrimaryContact : undefined}>
                <Copyable>
                  <ProjectContact>{primaryContact.name}</ProjectContact>
                  <CopyButton value={primaryContact.name} />
                </Copyable>
              </EditableWrapper>

              {Boolean(primaryPhone) && (
                <Copyable>
                  <PhoneNumber value={primaryPhone} />
                  <CopyButton value={primaryPhone} />
                </Copyable>
              )}

              {Boolean(primaryEmail) && (
                <Copyable>
                  <EmailAddress value={primaryEmail} />
                  <CopyButton value={primaryEmail} />
                </Copyable>
              )}

              {(!primaryPhone || !primaryEmail) && <div />}
            </ProjectContacts>
          </PrimaryContactBlock>
        )}

        <ProjectContacts>
          <ProjectContact>
            <ProjectContactLabel>Owner</ProjectContactLabel>
            <EditableField project={record} propertyName="ownerId">
              <EditableWrapper isEditable={canEdit}>
                <ProjectContactInfo>
                  {record.owner?.id && (
                    <>
                      <UserAvatar user={record.owner} />
                      <span>{`${record.owner.firstName} ${record.owner.lastName}`}</span>
                    </>
                  )}

                  {!record.owner && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>

          <ProjectContact>
            <ProjectContactLabel>Sales rep</ProjectContactLabel>
            <EditableField project={record} propertyName="salesRepId">
              <EditableWrapper isEditable={canEdit}>
                <ProjectContactInfo>
                  {record.salesRep && (
                    <>
                      <UserAvatar user={record.salesRep} />
                      <span>{`${record.salesRep.firstName} ${record.salesRep.lastName}`}</span>
                    </>
                  )}
                  {!record.salesRep && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>

          <ProjectContact>
            <ProjectContactLabel>Project manager</ProjectContactLabel>
            <EditableField project={record} propertyName="projectManagerId">
              <EditableWrapper isEditable={canEdit}>
                <ProjectContactInfo>
                  {record.projectManager && (
                    <>
                      <UserAvatar user={record.projectManager} />
                      <span>{`${record.projectManager.firstName} ${record.projectManager.lastName}`}</span>
                    </>
                  )}

                  {!record.projectManager && '-'}
                </ProjectContactInfo>
              </EditableWrapper>
            </EditableField>
          </ProjectContact>
        </ProjectContacts>

        <div>
          <ProjectContactLabel>Description</ProjectContactLabel>
          <Description project={record} />
        </div>

        <ProjectContacts>
          <div>
            <ProjectContactLabel>Created at</ProjectContactLabel>
            <StaticPropertyValue>{moment(record.createdAt).format('MM/DD/YY hh:mma')}</StaticPropertyValue>
          </div>
          <div>
            <ProjectContactLabel>Last activity</ProjectContactLabel>
            <StaticPropertyValue>{moment(record.lastActivity).format('MM/DD/YY hh:mma')}</StaticPropertyValue>
          </div>
          <div>
            <ProjectContactLabel>Status</ProjectContactLabel>
            <EditableField project={record} propertyName="status">
              <EditableWrapper isEditable={canEdit}>
                <StatusContainer>
                  <Status status={record.status} />
                  {getColorAndLabel({ status: record.status }).label}
                </StatusContainer>
              </EditableWrapper>
            </EditableField>
          </div>
        </ProjectContacts>
      </InfoBlock>

      <CustomProperties recordId={projectId} />
    </Container>
  );
};
