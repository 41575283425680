import { InfiniteData, useMutation, useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import notificationsApi from '@services/api/notificationsApi';
import { ReadBatchFeedDTO } from '@services/api/types';
import { errorHandler } from '@services/api/helpers';
import { FeedInbox } from '@generated/types/graphql';
import { useToast } from '..';
import { PaginatedNotifications } from './useNotificationList';

export const useMarkReadNotification = () => {
  const queryClient = useQueryClient();
  const { showError } = useToast();

  return useMutation<void, Error, ReadBatchFeedDTO & { refetchUnreadCounter: boolean }>(
    async ({
      refetchUnreadCounter: _refetchUnreadCounter,
      ...dto
    }: ReadBatchFeedDTO & { refetchUnreadCounter: boolean }) => {
      try {
        await notificationsApi.readBatch(dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onMutate: async ({ inboxIds, read, refetchUnreadCounter }) => {
        if (refetchUnreadCounter) {
          await queryClient.cancelQueries([ReactQueryKey.InboxNotificationsUnreadCounter]);
        } else {
          queryClient.setQueriesData<number | undefined>([ReactQueryKey.InboxNotificationsUnreadCounter], (old) => {
            if (!old) {
              return old;
            }

            return read ? old - inboxIds.length : old + inboxIds.length;
          });
        }
        queryClient.setQueriesData<FeedInbox | undefined>(
          [ReactQueryKey.InboxNotificationsById, inboxIds[0]],
          (old) => {
            if (!old) {
              return old;
            }

            return {
              ...old,
              read
            };
          }
        );

        queryClient.setQueriesData<InfiniteData<PaginatedNotifications> | undefined>(
          [ReactQueryKey.InboxNotifications],
          (old) => {
            if (!old) {
              return old;
            }

            return {
              ...old,
              pages: old.pages.map((page) => {
                return {
                  ...page,
                  notifications: page.notifications.map((notification) => {
                    if (inboxIds.includes(notification.id)) {
                      return {
                        ...notification,
                        read
                      };
                    }

                    return notification;
                  })
                };
              })
            };
          }
        );
      },
      onSuccess: async (_, { refetchUnreadCounter }) => {
        if (refetchUnreadCounter) {
          await queryClient.invalidateQueries([ReactQueryKey.InboxNotificationsUnreadCounter]);
        }
      },
      onError: (error: Error) => {
        showError(error.message);
      }
    }
  );
};
